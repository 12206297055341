import { ShareInfo } from "@prifilabs/backup.js/lib/BackupClient";
import { Radio, Text } from "@chakra-ui/react";

const ShareRecover = ({ share }: { share: ShareInfo }) => {
  function reason(share: ShareInfo) {
    if (share.count < share.threshold) {
      return "Not enough shares.";
    }
    if (share.is_recovering) {
      return "Currently trying to recover.";
    }
    if (share.transfer_count) {
      return "Currently trying to transfer.";
    }
    return "";
  }

  const whichReason = reason(share);

  return (
    <Radio
      value={share.uuid + "," + share.email}
      isDisabled={!!whichReason}
      display="flex"
      gap="10px"
    >
      <Text fontSize="md" align="left">
        <b>Share ID:</b> {share.uuid}
        <br></br>
        <b>Threshold:</b> {share.count} / {share.threshold}
        <br></br>
        <b>Email Address:</b> {share.email}
        <br></br>
        <b>Date:</b> {new Date(Number(share.timestamp) * 1000).toUTCString()}
        {!!whichReason && (
          <>
            <br></br>
            <b>Reason:</b> {whichReason}
          </>
        )}
      </Text>
    </Radio>
  );
};

export default ShareRecover;
