import { Text, Input, useBreakpointValue } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useBackupContract } from "../../contexts/BackupContractContext";

const RecoverSuccess = () => {
  const { backupContract } = useBackupContract();

  return (
    <>
      <Text padding={{ base: "4", md: "10" }}>
        <b>Step 5: Successfully Recovered! </b>{" "}
        <CheckCircleIcon marginLeft="2" marginBottom="1" color="teal.400" />
      </Text>

      <Text
        fontSize={useBreakpointValue({ base: "sm", md: "md" }, { ssr: false })}
        color="muted"
        alignItems={useBreakpointValue(
          {
            base: "center",
            md: "left",
          },
          { ssr: false }
        )}
        textAlign={useBreakpointValue(
          {
            base: "center",
            md: "left",
          },
          { ssr: false }
        )}
        paddingBottom={4}
      >
        Congratulations! Here is your recovered passhrase (or secret text):
      </Text>

      <Input value={backupContract.passphrase!} isReadOnly></Input>
    </>
  );
};

export default RecoverSuccess;
