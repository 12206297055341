import React from "react";
import { Container, FormLabel, Text, VStack } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useBackupContract } from "../../contexts/BackupContractContext";

const BackupSuccess = () => {
  const { backupContract } = useBackupContract();

  return (
    <div>
      <Container>
        <VStack marginBottom="10">
          <Text padding="10">
            {" "}
            <b>Step 5: Successfully Backed Up! </b>{" "}
            <CheckCircleIcon marginLeft="2" marginBottom="1" color="teal.400" />
          </Text>
          <FormLabel>
            Congratulations! Your passphrase (or secret text) has been backed up and is secure
            within the Secret Network. 
          </FormLabel>
          <FormLabel>Your Share ID: {backupContract.uuid}</FormLabel>
        </VStack>
      </Container>
    </div>
  );
};

export default BackupSuccess;
