import React, { useState } from "react";

type BackupContractType = {
  tid: string | undefined | null;
  key: string | undefined | null;
  nonce: string | undefined | null;
  hash: string | undefined | null;
  uuid: string | undefined | null;
  addr: string | undefined | null;
  passphrase: string | undefined | null;
};

type ProviderProps = {
  children?: React.ReactNode;
};

type BackupContextType = {
  backupContract: BackupContractType;
  setBackupContract: React.Dispatch<React.SetStateAction<BackupContractType>>;
};

const Context = React.createContext<BackupContextType>({} as BackupContextType);

const ContractProvider = (props: ProviderProps) => {
  const [backupContract, setBackupContract] = useState<BackupContractType>({
    tid: undefined,
    key: undefined,
    nonce: undefined,
    hash: undefined,
    uuid: undefined,
    addr: undefined,
    passphrase: undefined,
  });

  return (
    <Context.Provider value={{ backupContract, setBackupContract }}>
      {props.children}
    </Context.Provider>
  );
};

export default ContractProvider;

export const useBackupContract = () => React.useContext(Context);
