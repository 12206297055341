import {
  Container,
  Text,
  Box,
  OrderedList,
  ListItem,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";

import ConnectWalletModal from "../common/ConnectWallet/ConnectWalletModal";
import { useWalletModal } from "../../contexts/WalletModalContext";

type StepType = {
  nextStep: () => void;
};

const RecoverStep0 = (props: StepType) => {
  const { navModalHelper } = useWalletModal();

  function next() {
    return navModalHelper(() => props.nextStep());
  }

  return (
    <div>
      <Container>
        <ConnectWalletModal />
        <Box
          fontSize={useBreakpointValue(
            { base: "sm", md: "md" },
            { ssr: false }
          )}
          color="muted"
          alignItems={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          textAlign={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          padding={{ base: "0", md: "12" }}
        >
          <Text marginTop={2} fontSize="md">
            To recover your mnemonic phrase, you will need:
          </Text>
          <OrderedList marginTop={2} textAlign="left">
            <ListItem>
              <b>A Kepler Wallet with SCRT.</b> No need to use the same
              wallet as for the backup, you can create a completely new wallet.
            </ListItem>
            <ListItem>
              <b>The passphrase lock.</b> This means you will need to ask the
              people you trusted during the backup process to transfer back
              their piece of the passphrase lockback to your new wallet. You do
              not need to get them all back but at least a certain number of
              them (based on the threshold number set during backup).
            </ListItem>
            <ListItem>
              <b>
                The email address that you have used during the backup process.
              </b>
            </ListItem>
          </OrderedList>
        </Box>
        <Button
          bg="orange.400"
          color="white"
          onClick={next}
          marginTop={4}
          size={{ base: "sm", md: "md" }}
        >
          Let's start
        </Button>
      </Container>
    </div>
  );
};

export default RecoverStep0;
