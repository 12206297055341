import {
  Box,
  Container,
  Stack,
  Link,
  Text,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        bg={useColorModeValue("gray.50", "gray.900")}
        color={useColorModeValue("gray.700", "gray.200")}
        position="fixed"
        bottom="0"
        width="100%"
      >
        <Container
          as={Stack}
          py={{ base: 2, md: 4 }}
          maxWidth="4xl"
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 2, md: 4 }}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
          fontSize={{ base: "xs", md: "sm" }}
        >
          <Text>© 2022-2023 PriFi Labs Inc. All rights reserved.</Text>
          <Stack direction={"row"} spacing={9}>
            <Link onClick={() => navigate("/locker/privacy")}>
              {useBreakpointValue({ base: "Privacy", sm: "Privacy Policy" })}
            </Link>
            <Link onClick={() => navigate("/locker/terms")}>
              {useBreakpointValue({ base: "Terms", sm: "Terms & Conditions" })}
            </Link>
            <Link isExternal href={
                  process.env.REACT_APP_SECRET_CHAIN === "pulsar-2"
                    ? "https://secretnodes.com/pulsar"
                    : "https://secretnodes.com/"
                }>
              {useBreakpointValue({ base: "Contract", sm: "Review Contract" })}
            </Link>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
