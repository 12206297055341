import React, { useState } from "react";
import {
  FormControl,
  Input,
  Button,
  Container,
  HStack,
  useToast,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useUser } from "../../contexts/UserContext";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { useBackupContract } from "../../contexts/BackupContractContext";
import TransactionHash from "../common/TransactionHash/TransactionHash";
import { useWallet } from "../../contexts/WalletContext";
import axios from "axios";

type StepType = {
  setDisableNext: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  nextStep: () => void;
};

const BackupStep1 = (props: StepType) => {
  const { backupContract, setBackupContract } = useBackupContract();
  const { userInfo, setUserInfo } = useUser();
  const { keplr } = useWallet();
  const [valid, setValid] = useState<boolean | undefined>();
  const [isEmailSubmitting, setIsEmailSubmitting] = useState(false);
  const toast = useToast();

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChangeEmailInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isValidEmail(event.target.value)) {
      setValid(true);
    } else {
      setValid(false);
    }
    setUserInfo({ ...userInfo, email: event.target.value });
  };

  async function addEmailSubmit(event: React.FormEvent) {
    event.preventDefault();
    setIsEmailSubmitting(true);
    if (keplr.backupClient && keplr.secretJS) {
      try {
        const { tid, key, hash } = await keplr.backupClient.registerBackup(
          userInfo.email
        );
        if (process.env.REACT_APP_MOCK_MAILER && keplr.mailerClient) {
          const { code } = await keplr.mailerClient.getBackup(tid);
          console.log("code", code);
        } else {
          await axios.post(
            process.env.REACT_APP_MAILER_URL + "/api/backup",
            {
              tid,
            },
            { responseType: "text" }
          );
        }
        toast({
          title: "Step 2: Email Successfully Added.",
          description: TransactionHash(hash),
          position: "bottom-right",
          status: "success",
          duration: null,
          isClosable: true,
        });
        setBackupContract({
          ...backupContract,
          key,
          tid,
        });
        props.nextStep();
      } catch (err) {
        console.error(err);
        toast({
          title: "Step 2: Email Failed to Be Added.",
          description: TransactionHash(""),
          position: "bottom-right",
          status: "error",
          duration: null,
          isClosable: true,
        });
      }
    }
    setIsEmailSubmitting(false);
  }

  return (
    <Container>
      <Text padding={{ base: "4", md: "10" }}>
        <b>Step 2: Enter Your Email</b>
      </Text>
      <Text
        fontSize={useBreakpointValue({ base: "sm", md: "md" }, { ssr: false })}
        color="muted"
        alignItems={useBreakpointValue(
          {
            base: "center",
            md: "left",
          },
          { ssr: false }
        )}
        textAlign={useBreakpointValue(
          {
            base: "center",
            md: "left",
          },
          { ssr: false }
        )}
        paddingBottom={4}
      >
        The application will send a confirmation code to that email address
      </Text>
      <form onSubmit={addEmailSubmit}>
        <FormControl marginBottom="20">
          <HStack>
            <Input
              type="email"
              value={userInfo.email}
              placeholder="john@prifilabs.com"
              required
              onChange={(e) => handleChangeEmailInput(e)}
            />
            {valid ? (
              <CheckCircleIcon color="teal.400" />
            ) : (
              <WarningIcon color="orange.300" />
            )}
            <Button
              bg="orange.400"
              color="white"
              type="submit"
              isDisabled={!valid}
              isLoading={isEmailSubmitting}
              marginTop="10"
            >
              Add Email
            </Button>
          </HStack>
        </FormControl>
      </form>
    </Container>
  );
};

export default BackupStep1;
