import Sidebar from "../../components/Sidebar/Sidebar";
import Footer from "../../components/common/Footer/Footer";
import HomeCard from "../../components/HomeCards/HomeCard";

const DashboardHome = (): JSX.Element => {
  const currentPath = window.location.pathname;
  return (
    <>
      <Sidebar currentPath={currentPath}>
        <HomeCard></HomeCard>
      </Sidebar>
      <Footer></Footer>
    </>
  );
};

export default DashboardHome;
