import React, { useState } from "react";
import {
  FormControl,
  Button,
  Container,
  useToast,
  Input,
  HStack,
  VStack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useUser } from "../../contexts/UserContext";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { useBackupContract } from "../../contexts/BackupContractContext";
import TransactionHash from "../common/TransactionHash/TransactionHash";
import { useWallet } from "../../contexts/WalletContext";
import axios from "axios";
import { isValidWallet } from "../../utils/utils";

type StepType = {
  setDisableNext: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  nextStep: () => void;
};

const TransferStep2 = (props: StepType) => {
  const { keplr } = useWallet();
  const { backupContract, setBackupContract } = useBackupContract();
  const { userInfo, setUserInfo } = useUser();

  const [valid, setValid] = useState<boolean | undefined>();

  const [isRegisterSubmitting, setIsRegisterSubmitting] = useState(false);

  const handleChangeWallet = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidWallet(event.target.value)) {
      setValid(true);
    } else {
      setValid(false);
    }
    setUserInfo({ ...userInfo, recipient: event.target.value });
  };

  async function submitSelection(event: React.FormEvent) {
    event.preventDefault();
    setIsRegisterSubmitting(true);

    if (!backupContract.tid || !backupContract.key) {
      throw new Error("Fatal: Some prerequisite data is undefined");
    }

    if (keplr.backupClient) {
      try {
        const { hash } = await keplr.backupClient.confirmTransfer(
          backupContract.tid,
          backupContract.key,
          userInfo.recipient
        );

        if (process.env.REACT_APP_MOCK_MAILER && keplr.mailerClient) {
          const { code } = await keplr.mailerClient.getTransfer(
            backupContract.tid
          );
          console.log("code", code);
        } else {
          await axios.post(
            process.env.REACT_APP_MAILER_URL + "/api/transfer",
            {
              tid: backupContract.tid,
            },
            { responseType: "text" }
          );
        }

        setBackupContract({
          ...backupContract,
          hash,
        });

        toast({
          title: "Step 3: Successfully Sent Transfer Request.",
          description: TransactionHash(hash),
          position: "bottom-right",
          status: "success",
          duration: null,
          isClosable: true,
        });
        props.nextStep();
      } catch (err) {
        toast({
          title: "Step 3: Failed to Send Transfer Request.",
          description: TransactionHash(""),
          position: "bottom-right",
          status: "error",
          duration: null,
          isClosable: true,
        });
      }
    }

    console.log({
      wallet: userInfo.recipient,
      selected: userInfo.selected,
      email: userInfo.email,
    });

    setIsRegisterSubmitting(false);
  }

  //Toasts:

  const toast = useToast();

  return (
    <div>
      <Container>
        <Text padding="10">
          <b>Step 3: Choose Recipient</b>
        </Text>
        <Text
          fontSize={useBreakpointValue(
            { base: "sm", md: "md" },
            { ssr: false }
          )}
          color="muted"
          alignItems={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          textAlign={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          paddingBottom={4}
        >
          Enter the Secret address of the new owner:
        </Text>
        <form onSubmit={submitSelection}>
          <FormControl marginBottom="16">
            <Container>
              <HStack>
                <Input
                  type="text"
                  placeholder="secret123..."
                  required
                  onChange={(e) => handleChangeWallet(e)}
                />
                {valid ? (
                  <CheckCircleIcon color="teal.400" />
                ) : (
                  <WarningIcon color="orange.300" />
                )}
              </HStack>
            </Container>
            <VStack marginTop="8">
              <Button
                bg="orange.400"
                color="white"
                type="submit"
                marginTop="10"
                isLoading={isRegisterSubmitting}
                isDisabled={!valid}
              >
                Confirm Transfer Request
              </Button>
            </VStack>
          </FormControl>
        </form>
      </Container>
    </div>
  );
};

export default TransferStep2;
