import { addressToBytes } from "secretjs";

export function trimSecret(address: string) {
  return address.slice(0, 8) + "..." + address.slice(-4);
}

export function trimHash(address: string) {
  return address.slice(0, 4) + "..." + address.slice(-4);
}

export function trimPath(currentPath: string) {
  if (currentPath.length > 0) {
    const path = currentPath.substring(1);
    const finalPath = path.charAt(0).toUpperCase() + path.slice(1);
    if (finalPath.length === 0) {
      return "Home";
    }
    return finalPath;
  } else {
    return "";
  }
}

export function isValidWallet(wallet: string) {
  if (!wallet.startsWith("secret")) {
    return false;
  }
  try {
    addressToBytes(wallet);
  } catch {
    return false;
  }
  return true;
}
