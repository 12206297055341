import {
  Container,
  Text,
  Box,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";

import ConnectWalletModal from "../common/ConnectWallet/ConnectWalletModal";
import { useWalletModal } from "../../contexts/WalletModalContext";

type StepType = {
  nextStep: () => void;
};

const TransferStep0 = (props: StepType) => {
  const { navModalHelper } = useWalletModal();

  function next() {
    return navModalHelper(() => props.nextStep());
  }

  return (
    <div>
      <Container>
        <ConnectWalletModal />
        <Box
          fontSize={useBreakpointValue(
            { base: "sm", md: "md" },
            { ssr: false }
          )}
          color="muted"
          alignItems={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          textAlign={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          padding={{ base: "0", md: "12" }}
        >
          <Text marginTop={2} fontSize="md">
            <b>Transfer:</b>
          </Text>
          <Text marginTop={2} textAlign="left">
            You can request to transfer any of your passphrase share to someone
            else. Once this request process has been completed, its original
            owner will receive an email with a confirmation code for that
            transfer. The transfer will be final only after the owner has
            approved the transfer.
          </Text>
        </Box>
        <Button
          bg="orange.400"
          color="white"
          onClick={next}
          marginTop={4}
          size={{ base: "sm", md: "md" }}
        >
          Let's start
        </Button>
      </Container>
    </div>
  );
};

export default TransferStep0;
