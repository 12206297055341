import React, { useState } from "react";
import {
  FormControl,
  Input,
  Button,
  useToast,
  Container,
  HStack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useUser } from "../../contexts/UserContext";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { useBackupContract } from "../../contexts/BackupContractContext";
import { useWallet } from "../../contexts/WalletContext";

type StepType = {
  setDisableNext: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  nextStep: () => void;
};

const RecoverStep2 = (props: StepType) => {
  const { keplr } = useWallet();
  const { backupContract, setBackupContract } = useBackupContract();
  const { userInfo } = useUser();
  const [valid, setValid] = useState<boolean | undefined>();
  const [code, setCode] = useState("");

  const toast = useToast();

  function isValidVerificationCode(verificationCode: string) {
    return /....../.test(verificationCode);
  }

  const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidVerificationCode(event.target.value)) {
      setValid(true);
    } else {
      setValid(false);
    }
    setCode(event.target.value);
  };

  async function transfer(event: React.FormEvent) {
    event.preventDefault();

    if (keplr.backupClient) {
      if (!backupContract.key) {
        throw new Error("Fatal: Some prerequisite data is undefined");
      }
      try {
        const nonce = await keplr.backupClient.checkCodeRecovery(
          backupContract.key,
          code
        );
        toast({
          title: "Step 3: Code Successfully Verified.",
          description: "Proceed to next step.",
          position: "bottom-right",
          status: "success",
          duration: null,
          isClosable: true,
        });
        setBackupContract({ ...backupContract, nonce });
        props.nextStep();
      } catch (err) {
        toast({
          title: "Step 3: Code Failed to Be Verified.",
          description: "Repeat the verification process.",
          position: "bottom-right",
          status: "error",
          duration: null,
          isClosable: true,
        });
      }
    }
  }

  return (
    <div>
      <Container>
        <Text padding="10">
          <b>Step 3: Verify Your Email</b>
        </Text>
        <Text
          fontSize={useBreakpointValue(
            { base: "sm", md: "md" },
            { ssr: false }
          )}
          color="muted"
          alignItems={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          textAlign={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          paddingBottom={4}
        >
          An email has been sent to <b>{userInfo.email}</b>.
        </Text>
        <Text
          fontSize={useBreakpointValue(
            { base: "sm", md: "md" },
            { ssr: false }
          )}
          color="muted"
          alignItems={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          textAlign={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          paddingBottom={4}
        >
          Please enter the verification code:
        </Text>
        <form onSubmit={transfer}>
          <FormControl marginBottom="20">
            <HStack>
              <Input
                value={code}
                placeholder="XXXXXX"
                required
                onChange={(e) => handleChangeCode(e)}
              />
              {valid ? (
                <CheckCircleIcon color="teal.400" />
              ) : (
                <WarningIcon color="orange.300" />
              )}
              <Button
                bg="orange.400"
                color="white"
                type="submit"
                isDisabled={!valid}
                marginTop="10"
              >
                Verify
              </Button>
            </HStack>
          </FormControl>
        </form>
      </Container>
    </div>
  );
};

export default RecoverStep2;
