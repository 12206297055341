import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from "@chakra-ui/react";
import { useWalletModal } from "../../../contexts/WalletModalContext";
import ConnectWalletButton from "./ConnectWalletButton";

const ConnectWalletModal = () => {
  const { isModalOpen, onModalClose, whereTo } = useWalletModal();

  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} size="md">
      <ModalOverlay />
      <ModalContent alignItems="center">
        <ModalHeader>Please connect your wallet to continue:</ModalHeader>
        <ModalBody mb="20px">
          <ConnectWalletButton callback={whereTo} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConnectWalletModal;
