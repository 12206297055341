import { Container, Text, VStack, useBreakpointValue } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useUser } from "../../contexts/UserContext";

const TransferSuccess = () => {
  const { userInfo } = useUser();

  return (
    <div>
      <Container>
        <VStack marginBottom="10">
          <Text padding="10">
            <b>Step 4: Your request was sent!</b>
            <CheckCircleIcon marginLeft="2" marginBottom="1" color="teal.400" />
          </Text>
          <Text
            fontSize={useBreakpointValue(
              { base: "sm", md: "md" },
              { ssr: false }
            )}
            color="muted"
            alignItems={useBreakpointValue(
              {
                base: "center",
                md: "left",
              },
              { ssr: false }
            )}
            textAlign={useBreakpointValue(
              {
                base: "center",
                md: "left",
              },
              { ssr: false }
            )}
            paddingBottom={4}
          >
            You have requested to transfer one of the share for passphrase ID{" "}
            <b>{userInfo.selected}</b> to the new recipient{" "}
            <b>{userInfo.recipient}</b>.
          </Text>
          <Text
            fontSize={useBreakpointValue(
              { base: "sm", md: "md" },
              { ssr: false }
            )}
            color="muted"
            alignItems={useBreakpointValue(
              {
                base: "center",
                md: "left",
              },
              { ssr: false }
            )}
            textAlign={useBreakpointValue(
              {
                base: "center",
                md: "left",
              },
              { ssr: false }
            )}
            paddingBottom={4}
          >
            To finalize the transfer, a transfer code has been sent to the
            original owner <b>{userInfo.email}</b>. The transfer will be final
            only after that owner has approved the transfer.
          </Text>
        </VStack>
      </Container>
    </div>
  );
};

export default TransferSuccess;
