import { useState } from "react";
import { Flex, Container } from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import Sidebar from "../../components/Sidebar/Sidebar";
import RecoverStep0 from "../../components/RecoverSteps/RecoverStep0";
import RecoverStep1 from "../../components/RecoverSteps/RecoverStep1";
import RecoverStep2 from "../../components/RecoverSteps/RecoverStep2";
import RecoverStep3 from "../../components/RecoverSteps/RecoverStep3";
import RecoverSuccess from "../../components/RecoverSteps/RecoverSuccess";
import Footer from "../../components/common/Footer/Footer";

import ReactGA from "react-ga";

export const DashboardRecover = (): JSX.Element => {
  const currentPath = window.location.pathname;
  const { nextStep, setStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const [, setDisableNext] = useState<boolean | undefined>(true);

  function next(label: string) {
    return function () {
      if (process.env.REACT_APP_ENV === "production") {
        ReactGA.event({
          category: "Completed",
          action: "Recover",
          label: label,
        });
      }
      nextStep();
    };
  }

  return (
    <div>
      <Sidebar currentPath={currentPath}>
        <Container
          padding={{ base: "4", md: "12" }}
          maxW="5xl"
          bg="white"
          boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
          borderRadius="15px"
        >
          <Flex flexDir="column" width="100%">
            <Steps
              activeStep={activeStep}
              onClickStep={(step: number) => {
                if (activeStep > step) setStep(step);
              }}
              colorScheme="teal"
              variant="circles-alt"
            >
              <Step label="Start" key="RecoverStart">
                <RecoverStep0 nextStep={next("step1")} />
              </Step>
              <Step label="Select Passphrase" key="RecoverShares">
                <RecoverStep1
                  setDisableNext={setDisableNext}
                  nextStep={next("step2")}
                />
              </Step>
              <Step label="Verify Email" key="RecoverVerifyEmail">
                <RecoverStep2
                  setDisableNext={setDisableNext}
                  nextStep={next("step3")}
                />
              </Step>
              <Step label="Confirm" key="RecoverConfirm">
                <RecoverStep3
                  setDisableNext={setDisableNext}
                  nextStep={next("step4")}
                />
              </Step>
              <Step label="Success" key="RecoverSuccess">
                <RecoverSuccess />
              </Step>
            </Steps>
          </Flex>
        </Container>
      </Sidebar>

      <Footer></Footer>
    </div>
  );
};

export default DashboardRecover;
