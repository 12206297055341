import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  VStack,
  Container,
  HStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { useUser } from "../../contexts/UserContext";
import { useBackupContract } from "../../contexts/BackupContractContext";
import SliderThreshold from "../common/SliderNFT/SliderThreshold";
import SliderShares from "../common/SliderNFT/SliderShares";
import { LabelTooltip } from "../common/LabelTooltip/LabelTooltip";
import TransactionHash from "../common/TransactionHash/TransactionHash";
import { useWallet } from "../../contexts/WalletContext";
import { isValidWallet } from "../../utils/utils";

type StepType = {
  setDisableNext: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  nextStep: () => void;
};

const BackupStep3 = (props: StepType) => {
  const { userInfo, setUserInfo } = useUser();
  const { keplr } = useWallet();
  const { backupContract, setBackupContract } = useBackupContract();

  const [inputs, setInputs] = useState<string[]>(
    Array(userInfo.supply).fill("")
  );

  const [isDisabledTransfer, setIsDisabledTransfer] = useState(true);
  const [isTransferLoading, setIsTransferLoading] = useState(false);
  const toast = useToast();

  useEffect(
    function isDisabled() {
      function isValidWalletList(walletsList: string[]) {
        // checks that wallet list has no duplicates & all addresses are valid addresses.
        let isWallets = true;
        walletsList.forEach((walletAddress) => {
          if (!isValidWallet(walletAddress)) {
            isWallets = false;
          }
        });
        const isValid = isWallets;
        return isValid;
      }

      if (
        userInfo.secretText.length > 0 &&
        userInfo.supply >= userInfo.threshold &&
        isValidWalletList(inputs)
      ) {
        setIsDisabledTransfer(false);
      } else {
        setIsDisabledTransfer(true);
      }
    },
    [inputs, userInfo.secretText, userInfo.supply, userInfo.threshold]
  );

  // extend or slice array depending on supply
  useEffect(
    function () {
      setInputs((oldInputs) => {
        const newInputs =
          oldInputs.length < userInfo.supply
            ? oldInputs.concat(
                Array(userInfo.supply - oldInputs.length).fill("")
              )
            : oldInputs.slice(0, userInfo.supply);
        return newInputs;
      });
    },
    [userInfo.supply]
  );

  const handleSecretText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserInfo({ ...userInfo, secretText: event.target.value });
  };

  const handleUserInputChange = (
    position: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputs((oldInputs) => {
      const newInputs = [...oldInputs];
      newInputs[position] = e.target.value;
      return newInputs;
    });
  };

  async function confirmBackupTransfer(event: React.FormEvent) {
    event.preventDefault();
    setIsTransferLoading(true);
    if (keplr.backupClient && keplr.secretJS && backupContract.tid) {
      if (!backupContract.tid || !backupContract.key || !backupContract.nonce) {
        throw new Error("Fatal: Some prerequisite data is undefined");
      }

      try {
        const { tid, key, nonce } = backupContract;
        const { secretText, threshold } = userInfo;
        const { uuid, hash } = await keplr.backupClient.confirmBackup(
          tid,
          key,
          nonce,
          secretText,
          threshold,
          inputs
        );
        toast({
          title: "Step 4: Successfully Backed Up.",
          description: TransactionHash(hash),
          position: "bottom-right",
          status: "success",
          duration: null,
          isClosable: true,
        });
        setBackupContract({
          ...backupContract,
          uuid,
          hash,
        });
        props.nextStep();
      } catch (err) {
        toast({
          title: "Step 4: Failed to Backup.",
          description: TransactionHash(""),
          position: "bottom-right",
          status: "error",
          duration: null,
          isClosable: true,
        });
      }
    }
    setIsTransferLoading(false);
  }

  return (
    <div>
      <Container>
        <Text padding="10">
          <b>Step 4: Enter Your Passphrase</b>
        </Text>
        <form onSubmit={confirmBackupTransfer}>
          <FormControl>
            <FormLabel>Your passphrase (or secret text):</FormLabel>
            <HStack>
              <Textarea
                value={userInfo.secretText}
                required
                onChange={(e) => handleSecretText(e)}
              />
            </HStack>
            <LabelTooltip
              label="Number of shares to emit in total:"
              text="Supply is the number of shares you would like to issue."
            ></LabelTooltip>
            <SliderShares />
            <LabelTooltip
              label="Number of shares needed to recover the passphrase (a.k.a threshold):"
              text="Threshold is the number of shares you would need to have in your wallet in order to recover your passphrase."
            ></LabelTooltip>
            <SliderThreshold />
            <FormLabel marginTop="5">
              Enter the wallet address of people you trust with those shares.
            </FormLabel>
            <VStack>
              {inputs.map((text, i) =>
                i < userInfo.supply ? (
                  <HStack key={100 * i} width="2xl">
                    <Input
                      key={i}
                      placeholder="secret123..."
                      onChange={(e) => handleUserInputChange(i, e)}
                    />
                    {isValidWallet(text) ? (
                      <CheckCircleIcon color="teal.400" />
                    ) : (
                      <WarningIcon color="orange.300" />
                    )}
                  </HStack>
                ) : null
              )}
            </VStack>
            <Button
              bg="orange.400"
              color="white"
              type="submit"
              marginTop="10"
              isLoading={isTransferLoading}
              isDisabled={isDisabledTransfer}
            >
              Transfer
            </Button>
          </FormControl>
        </form>
      </Container>
    </div>
  );
};

export default BackupStep3;
