import React, { useState } from "react";

type UserType = {
  walletAddress: string;
  email: string;
  secretText: string;
  selected: string;
  recipient: string;
  supply: number;
  threshold: number;
};

type ProviderProps = {
  children?: React.ReactNode;
};

type UserContextType = {
  userInfo: UserType;
  userInitialState: UserType;
  setUserInfo: React.Dispatch<React.SetStateAction<UserType>>;
};

const userInitialState = {
  walletAddress: "",
  email: "",
  secretText: "",
  selected: "",
  recipient: "",
  wallets: [""],
  supply: 5,
  threshold: 3,
};

const Context = React.createContext<UserContextType>({} as UserContextType);

const UserProvider = (props: ProviderProps) => {
  const [userInfo, setUserInfo] = useState<UserType>({ ...userInitialState });

  return (
    <Context.Provider value={{ userInfo, userInitialState, setUserInfo }}>
      {props.children}
    </Context.Provider>
  );
};

export default UserProvider;

export const useUser = () => React.useContext(Context);
