import { ReactNode } from "react";
import {
  Link,
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
} from "@chakra-ui/react";
import { FiMenu, FiCornerDownLeft, FiRotateCcw } from "react-icons/fi";
import { IconType } from "react-icons";
import { HiCheck, HiHome } from "react-icons/hi";
import { BiTransferAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { LogoText } from "../common/LogoText/LogoText";
import ConnectWalletButton from "../common/ConnectWallet/ConnectWalletButton";
import NavItem from "./NavItem/NavItem";

interface LinkItemProps {
  name: string;
  icon: IconType;
  handle: () => void;
}

interface SidebarMainProps {
  children: ReactNode;
  currentPath: string;
}

export default function Sidebar({ children, currentPath }: SidebarMainProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      minH="100vh"
      bg={useColorModeValue("gray.100", "gray.900")}
      marginBottom={{ base: 4, md: 12 }}
    >
      <SidebarContent
        onClose={() => onClose}
        currentPath={currentPath}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} currentPath={currentPath} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
  currentPath: string;
}

const SidebarContent = ({ onClose, currentPath, ...rest }: SidebarProps) => {
  const navigate = useNavigate();

  const LinkItems: Array<LinkItemProps> = [
    {
      name: "Home",
      icon: HiHome,
      handle: () => navigate("/"),
    },
    {
      name: "Backup",
      icon: FiCornerDownLeft,
      handle: () => navigate("/backup"),
    },
    {
      name: "Recovery",
      icon: FiRotateCcw,
      handle: () => navigate("/recovery"),
    },
    {
      name: "Transfer",
      icon: BiTransferAlt,
      handle: () => navigate("/transfer"),
    },
    {
      name: "Approve",
      icon: HiCheck,
      handle: () => navigate("/approve"),
    },
  ];

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="6" justifyContent="space-between">
        <Link
          href={process.env.REACT_APP_MAIN_URL}
          style={{ textDecoration: "none" }}
        >
          <LogoText></LogoText>
        </Link>
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          name={link.name}
          icon={link.icon}
          handle={link.handle}
          currentPath={currentPath}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Box>
      <Box display="flex" justifyContent="flex-start"></Box>
      <Flex
        ml={{ base: 0, md: 60 }}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={useColorModeValue("white", "gray.900")}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gra y.700")}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}
      >
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Box display={{ base: "flex", md: "none" }}>
          <LogoText></LogoText>
        </Box>

        <HStack spacing={{ base: "2", md: "4" }}>
          <Flex alignItems={"center"}>
            <ConnectWalletButton />
          </Flex>
        </HStack>
      </Flex>
    </Box>
  );
};
