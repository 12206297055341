import { Radio, Text } from "@chakra-ui/react";
import { ShareInfo } from "@prifilabs/backup.js/lib/BackupClient";

const ShareTransfer = ({ share }: { share: ShareInfo }) => {
  function reason(share: ShareInfo) {
    if (share.is_recovering) {
      return "Currently trying to recover.";
    }
    if (share.transfer_count >= share.count) {
      return "Trying to transfer too many shares at once.";
    }
    return "";
  }

  const whichReason = reason(share);

  return (
    <Radio
      value={share.uuid + "," + share.email}
      display="flex"
      gap="10px"
      isDisabled={!!whichReason}
    >
      <Text fontSize="md" align="left">
        <b>Passphrase ID:</b> {share.uuid}
        <br></br>
        <b>Share Count:</b> {share.count}
        <br></br>
        <b>Email Address:</b> {share.email}
        <br></br>
        <b>Date:</b> {new Date(Number(share.timestamp) * 1000).toUTCString()}
        {!!whichReason && (
          <>
            <br></br>
            <b>Reason:</b> {whichReason}
          </>
        )}
      </Text>
    </Radio>
  );
};

export default ShareTransfer;
