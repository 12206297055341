import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Button,
  Container,
  VStack,
  Text,
  useToast,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useUser } from "../../contexts/UserContext";
import { useWallet } from "../../contexts/WalletContext";
import { GrRefresh } from "react-icons/gr";
import ShareListTransfer from "../common/shares/ShareListTransfer";
import TransactionHash from "../common/TransactionHash/TransactionHash";
import { useBackupContract } from "../../contexts/BackupContractContext";
import { ShareInfo } from "@prifilabs/backup.js/lib/BackupClient";

type StepType = {
  setDisableNext: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  nextStep: () => void;
};

const TransferStep1 = (props: StepType) => {
  const { keplr } = useWallet();
  const { userInfo } = useUser();
  const toast = useToast();
  const [value, setValue] = useState("");
  const { backupContract, setBackupContract } = useBackupContract();
  const [isRegisterSubmitting, setIsRegisterSubmitting] = useState(false);
  const [shareList, setShareList] = useState<ShareInfo[]>([]);

  async function loadShares() {
    if (keplr.backupClient && keplr.secretJS) {
      try {
        const shares = (await keplr.backupClient.getShareCount()).sort((a, b) =>
          Number(BigInt(b.timestamp) - BigInt(a.timestamp))
        );
        setShareList(shares);
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function submitSelection(event: React.FormEvent) {
    event.preventDefault();
    setIsRegisterSubmitting(true);

    if (keplr.backupClient) {
      try {
        const { tid, key, hash } = await keplr.backupClient.registerTransfer(
          userInfo.selected
        );

        toast({
          title: "Step 2: Successfully registered transfer.",
          description: TransactionHash(hash),
          position: "bottom-right",
          status: "success",
          duration: null,
          isClosable: true,
        });

        setBackupContract({
          ...backupContract,
          hash,
          tid,
          key,
        });
        props.nextStep();
      } catch (err) {
        console.error(err);
        toast({
          title: "Step 2: Failed to register transfer.",
          description: TransactionHash(""),
          position: "bottom-right",
          status: "error",
          duration: null,
          isClosable: true,
        });
      }
    }
    setIsRegisterSubmitting(false);
    console.log({
      wallet: userInfo.recipient,
      selected: userInfo.selected,
      email: userInfo.email,
    });
  }

  return (
    <div>
      <Container>
        <Text padding="10">
          <b>Step 2: Select Your Shares</b>
        </Text>
        <Text
          fontSize={useBreakpointValue(
            { base: "sm", md: "md" },
            { ssr: false }
          )}
          color="muted"
          alignItems={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          textAlign={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          paddingBottom={4}
        >
          Here are the passphrase shares that you own. Select the one you want
          to transfer.
        </Text>
        <form onSubmit={submitSelection}>
          <FormControl mb="16">
            <Container display="flex" flexDirection="column">
              {!!shareList && shareList?.length > 0 && (
                <>
                  <ShareListTransfer
                    shareList={shareList}
                    value={value}
                    setValue={setValue}
                  />
                </>
              )}
              {shareList?.length === 0 && (
                <FormLabel alignSelf="center">You have no shares </FormLabel>
              )}
            </Container>
            <VStack mt="30px">
              <Button onClick={loadShares} rightIcon={<GrRefresh />}>
                {shareList ? "Refresh Shares" : "Load My Shares"}
              </Button>
              {shareList ? (
                <Button
                  bg="orange.400"
                  color="white"
                  type="submit"
                  marginTop="10"
                  isDisabled={!value}
                  isLoading={isRegisterSubmitting}
                >
                  Confirm Selection
                </Button>
              ) : null}
            </VStack>
          </FormControl>
        </form>
      </Container>
    </div>
  );
};

export default TransferStep1;
