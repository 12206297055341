import { Box, Container, Heading } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { ListItem, OrderedList } from "@chakra-ui/react";

const TermsConditions = () => {
  return (
    <Container pb="10em">
      <Heading textAlign="center" size="3xl" py="2em">
        Terms and Conditions
      </Heading>

      <Box
        display="flex"
        flexDir="column"
        gap="2em"
        textAlign="left"
        fontSize="lg"
      >
        <Box display="flex" flexDir="column" gap="0.7em">
          <Text color="gray.400">Last updated on Nov, 2022</Text>
          <Text>
            THESE TERMS CREATE A BINDING CONTRACT BETWEEN YOU AND PriFi Labs
            Inc. BY USING OUR SERVICES (DEFINED BELOW), YOU AGREE TO BE BOUND BY
            THESE TERMS. IF YOU DO NOT ACCEPT THE TERMS, YOU MUST NOT AND ARE
            NOT AUTHORIZED TO USE ANY OF OUR SERVICES.
          </Text>
          <Text>
            FOR PERSONS RESIDING IN THE USA: THESE TERMS CONTAIN ADDITIONAL
            PROVISIONS APPLICABLE ONLY TO YOU. THEY CONTAIN AN ARBITRATION
            PROVISION. IF WE CANNOT RESOLVE A DISPUTE AMICABLY, ALL DISPUTES
            ARISING UNDER OR IN CONNECTION WITH THIS AGREEMENT MUST BE SETTLED
            IN BINDING ARBITRATION PER CLAUSE 28.4. ENTERING INTO THIS AGREEMENT
            CONSTITUTES A WAIVER OF YOUR RIGHT, IF ANY, TO PARTICIPATE IN A
            CLASS ACTION LAWSUIT OR A JURY TRIAL.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">1. What is the scope of the Terms?</Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              These Terms of Service (the “Agreement”) are a legal agreement
              between you (“you” or “yours”) and PriFi Labs Inc. (“PriFi Labs”,
              “we”, “our” or “us”). We are a company limited by shares
              registered in Toronto, Canada.
            </ListItem>
            <ListItem>
              By using any service offered by us, whether through
              https://prifilabs.com, any associated website, API, or mobile
              applications as further detailed in clause 3 (collectively, the
              “Services”), you agree that you have read, understood, and accept
              all of the terms and conditions contained in this Agreement,
              including the Privacy Policy incorporated herein by reference, as
              amended from time to time. If you do not agree with this
              Agreement, you must not use the Services.
            </ListItem>
            <ListItem>
              You are responsible for ensuring that all persons who access or
              use the Services through your device or internet connection are
              aware of this Agreement and its terms, and that they comply with
              them.
            </ListItem>
            <ListItem>
              Each time you use our Services you will be bound by the Agreement
              in force at that time. From time to time, we may change its terms.
              If we do this then we will publish those changes on
              https://prifilabs.com and you will be bound by those new terms the
              next time you use our Services. If you do not agree to those
              changes you must not use our Services. You can always ask us for
              the Agreement, which was in force when you used the Services by
              writing to thierry.sans@prifilabs.com. Every time you wish to use
              the Services, please check and ensure that you agree with the
              latest updated version of the Agreement.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            2. What do some of the capitalized terms mean in the Agreement?
          </Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              “Secret Network Blockchain” means a mathematically secured
              consensus ledger maintained on using the Cosmos Tendermint BFT and
              SecretWasm.
            </ListItem>
            <ListItem>
              “Transaction” means a change to the data set through a new entry
              in the continuous Secret Network Blockchain.
            </ListItem>
            <ListItem>
              “Smart Contract” means a piece of source code deployed as an
              application on the Secret Network Blockchain which can be
              executed, including self-execution of Transactions as well as
              execution triggered by 3rd parties.
            </ListItem>
            <ListItem>
              “Token” means a digital asset transferred in a Transaction,
              including SCRT, SNIP20, SNIP721 and SNIP1155 tokens.
            </ListItem>
            <ListItem>
              “Wallet” means a cryptographic storage solution permitting you to
              store cryptographic assets by correlation of a (i) Public Key and
              (ii) a Private Key or a Smart Contract to receive, manage and send
              Tokens.
            </ListItem>
            <ListItem>
              “Public Key” means a unique sequence of numbers and letters within
              the Secret Network Blockchain to distinguish the network
              participants from each other.
            </ListItem>
            <ListItem>
              “Private Key” means a unique sequence of numbers and/or letters
              required to initiate a Secret Network Blockchain Transaction and
              should only be known by the legal owner of the Wallet.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            3. What are the Services of the PriFi Labs?
          </Heading>
          <Text>Our Services primarily consist of:</Text>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              development and maintenance of a smart contract logic that is
              open-sourced and allows you to store secret texts, that can be
              restored by collecting assigned permissions from assigned Secret
              Network accounts derived from a Secret Network Public Key;
            </ListItem>
            <ListItem>
              facilitation of your decentralized storage of secret texts and
              ongoing interaction with it on the Secret Network Blockchain
              whether directly or through third party protocols or plugins
              (“Facilitation Services”) through the development, provision, and
              maintenance of:
              <OrderedList ml="4em" mt="0.7em" spacing="0.7em">
                {" "}
                <ListItem>
                  a web-based graphical user interface (“Interface”),
                </ListItem>
                <ListItem>
                  a command-line interface (“CLI”) running locally on the user’s
                  machine,
                </ListItem>
                <ListItem>software developer kits (“SDKs”),</ListItem>
                <ListItem>
                  backend services and application programming interfaces
                  (“APIs”);
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              development and provision of a graphical user interface (the
              “Dashboard”) to enable third-party developers and providers of
              decentralized applications and protocols on the Secret Network
              Blockchain (together “Apps”) to integrate custom plugins into the
              Interface.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            4. What do the Services of the PriFi Labs not consist of?
          </Heading>
          <Text>Our Services do not consist of:</Text>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              activity regulated by the The Financial Consumer Agency of Canada
              or any other regulatory agency;
            </ListItem>
            <ListItem>
              coverage underwritten by any regulatory agency’s compensation
              scheme;
            </ListItem>
            <ListItem>
              custody of your Secret Texts, Record of Assigned Accounts, Private
              Keys, Tokens or or the ability to remove or freeze your Tokens;
            </ListItem>
            <ListItem>the storage or transmission of fiat currencies;</ListItem>
            <ListItem>
              back-up services to recover your Private Keys, for whose
              safekeeping you are solely responsible;
            </ListItem>
            <ListItem>
              any form of legal, financial, accounting, tax or other
              professional advice regarding Transactions and their suitability
              to you; and
            </ListItem>
            <ListItem>
              the responsibility to monitor authorized Transactions or to check
              the correctness or completeness of Transactions before you are
              authorizing them.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            5. What do you need to know about third party services integrated
            through Dashboard or Facilitation Services?
          </Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              We provide the Dashboard and the Facilitation Services to you to
              interact with third-party systems. However, any activities you
              engage in with, or services you receive from, a third party is
              between you and that third party directly.
            </ListItem>
            <ListItem>
              This means specifically:
              <OrderedList ml="4em" mt="0.7em" spacing="0.7em">
                <ListItem>
                  We do not have any oversight over your activities with third
                  parties, and as such we do not and cannot make any
                  representation regarding their appropriateness and suitability
                  for you.
                </ListItem>
                <ListItem>
                  Facilitation Services and Dashboard may involve or contain
                  links or integrations to third party Apps that are not hosted,
                  owned, controlled or maintained by us. We also do not
                  participate in the transaction on such Apps and will and
                  cannot monitor, verify, censor or edit the functioning or
                  content of any App.
                </ListItem>
                <ListItem>
                  We have not conducted any security audit, bug bounty or formal
                  verification (whether internal or external) of the Apps
                  integrated on our Dashboard or connected through the
                  Facilitation Services.
                </ListItem>
                <ListItem>
                  We have no control over, do not recommend, endorse, or
                  otherwise take a position on the integrity, functioning of,
                  content and your use of these Apps, whose sole responsibility
                  lies with the person from whom such services or content
                  originated.
                </ListItem>
                <ListItem>
                  When you access or use those Apps you accept that there are
                  risks in doing so and that you alone assume any such risks
                  when choosing to interact with those Apps. We aren’t liable
                  for any errors or omissions or for any damages or loss you
                  might suffer through interacting with those Apps.
                </ListItem>
                <ListItem>
                  You should read the license requirements, terms and conditions
                  as well as privacy policy of each App that you access or use.
                  Certain Apps may involve complex financial transactions that
                  entail a high degree of risk.
                </ListItem>
                <ListItem>
                  You agree to release us from all liability for your access or
                  usage of any Apps through the Dashboard or Facilitation
                  Services.
                </ListItem>
                <ListItem>
                  If you contribute integrations to Apps to the Dashboard, you
                  are responsible for all content you contribute, in any manner,
                  to the Dashboard, and you must have all rights necessary to do
                  so, in the manner in which you contribute it. You are
                  responsible for all your activity in connection with any such
                  App.
                </ListItem>
                <ListItem>
                  Your interactions with persons found on or through the Apps,
                  including payment and delivery of goods and services,
                  financial transactions, and any other terms associated with
                  such dealings, are solely between you and such persons. You
                  agree that we shall not be responsible or liable for any loss
                  or damage of any sort incurred as the result of any such
                  dealings.
                </ListItem>
                <ListItem>
                  If there is a dispute between you and the App provider or/and
                  other users of the App, you agree that we are under no
                  obligation to become involved. In the event that you have a
                  dispute with one or more other users, you release us, our
                  officers, employees, agents, contractors and successors from
                  claims, demands, and damages of every kind or nature, known or
                  unknown, suspected or unsuspected, disclosed or undisclosed,
                  arising out of or in any way related to such disputes and/or
                  our Services.
                </ListItem>
              </OrderedList>
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            6. Are we responsible for the security of your Private Keys, seed
            words or other credentials?
          </Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              We shall not be responsible to secure your Private Keys, seed
              words, credentials or other means of authorization of your
              Wallet(s).
            </ListItem>
            <ListItem>
              You must own and control any Wallet you use in connection with our
              Services. You are responsible for implementing all appropriate
              measures for securing any Wallet you use, including any Private
              Key(s), seed words, credentials or other means of authorization
              necessary to access such storage mechanism(s).
            </ListItem>
            <ListItem>
              We exclude any and all liability for any security breaches or
              other acts or omissions, which result in your loss of access or
              custody of any cryptographic assets stored thereon.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            7. Are you eligible to use our Services and can we check your
            suitability?
          </Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              To access or use our Services, you must be able to form a legally
              binding contract with us. You must be of legal age in your
              jurisdiction to use the Services and you agree to provide
              legitimate and lawful documentation proving such status if
              requested or required by us.
            </ListItem>
            <ListItem>
              You must have the full right, power, and authority to enter into
              and comply with this Agreement on behalf of yourself and any
              company or legal entity for which you may access or use our
              Services.
            </ListItem>
            <ListItem>
              Our Services are operated out of Canada. The Services may not be
              available or appropriate for use in other jurisdictions. You must
              not use our Services if your use of them would be illegal or
              otherwise violate any law you are subject to. We are not liable
              for your compliance with such laws.
            </ListItem>
            <ListItem>
              You must not be, and will not be, located in any jurisdiction that
              is the subject of an embargo by Canada, the United Kingdom, the
              European Union or the United States and you are not listed on any
              list of prohibited or restricted parties by those foregoing.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            8. Can we terminate or limit your right to use our Services?
          </Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              We reserve the right, in our sole discretion and for whatever
              reason, but particularly in case you breach any term of this
              Agreement, to:
              <OrderedList ml="4em" mt="0.7em" spacing="0.7em">
                <ListItem>
                  terminate your right to use the Services with immediate
                  effect;
                </ListItem>
                <ListItem>
                  limit use of all PriFi Labs Interfaces, Mobile Apps or Command
                  Line Interfaces (the “PriFi Labs Clients”) to a specified
                  number of persons;
                </ListItem>
                <ListItem>
                  refuse to allow a person from using the PriFi Labs clients;
                  and/or
                </ListItem>
                <ListItem>
                  remove or exclude any person from using the PriFi Labs for
                  whatever reason.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              We will only be able to limit access to the PriFi Labs. At no time
              will we be able to access or transfer your funds without your
              consent.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            9. What licenses and access do we grant to you?
          </Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              All intellectual property rights in the PriFi Labs and the
              Services throughout the world belong to us as owner or our
              licensors and the rights in the Services and the Services are only
              licensed to you. Nothing in these terms gives you any rights in
              respect of any intellectual property owned by us or our licensors
              and you acknowledge that you do not acquire any ownership rights
              by downloading the Interface or any content from the Interface.
            </ListItem>
            <ListItem>
              If you are a consumer we license, but do not sell, to you the
              Services you download solely for your own personal, non-commercial
              use. If you are a business user we license, but do not sell, to
              you the Services you download to use solely for your own internal
              business use. We remain the owner of the Services at all times.
            </ListItem>
            <ListItem>
              The Services may contain code, commonly referred to as open source
              software, which is distributed under open source license terms,
              including terms which allow the free distribution and modification
              of the relevant software’s source code and/or which require all
              distributors to make such source code freely available upon
              request, including any contributions or modifications made by such
              distributor (“Open Source Software”). To the extent that the
              Services contain any Open Source Software, that element only is
              licensed to you under the relevant license terms of the applicable
              third party licensor (“Open Source Licence Terms”) and not under
              this Agreement, and you accept and agree to be bound by such Open
              Source Licence Terms.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            10. What can you expect from the Services and can we make changes to
            them?
          </Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              Except as set out in this Agreement, we do not warrant, represent
              or guarantee that the Services will be accurate, complete,
              correct, reliable integer, fit for purpose, secure or free from
              weaknesses, vulnerabilities or bugs.
            </ListItem>
            <ListItem>
              You understand and accept that you use the Services at your own
              risk.
            </ListItem>
            <ListItem>
              To the fullest extent permitted by law, we provide the Services to
              you “as is” and “as available” without any warranty,
              representation or assurance (whether express or implied) in
              relation to merchantability, fitness for a particular purpose,
              availability, security, title or non-infringement.
            </ListItem>
            <ListItem>
              We reserve the right to change the format and features of the
              Services by making any updates to Services available for you to
              download or, where your device settings permit it, by automatic
              delivery of updates.
            </ListItem>
            <ListItem>
              You are not obliged to download the updated Services, but we may
              cease to provide and/or update prior versions of the Services and,
              depending on the nature of the update, in some circumstances you
              may not be able to continue using the Services until you have
              downloaded the updated version.
            </ListItem>
            <ListItem>
              We may cease to provide and/or update content to the Services,
              with or without notice to you, if it improves the Services we
              provide to you, or we need to do so for security, legal or any
              other reasons.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            11. Do we have any fiduciary duties to you?
          </Heading>
          <Text>
            This Agreement is not intended to, and does not, create or impose
            any fiduciary duties on us. To the fullest extent permitted by law,
            you acknowledge and agree that we owe no fiduciary duties or
            liabilities to you or any other party, and that to the extent any
            such duties or liabilities may exist at law or in equity, those
            duties and liabilities are hereby irrevocably disclaimed, waived,
            and eliminated. You further agree that the only duties and
            obligations that we owe you are those set out expressly in this
            Agreement.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            12. What about third-party risk and the terms of third party
            platform providers and application stores?
          </Heading>
          <Text>
            The Services rely in part on third party and open-source software,
            including the Secret Network Blockchain, and the continued
            development and support by third parties. There is no assurance or
            guarantee that those third parties will maintain their support of
            their software or that open source software will continue to be
            maintained. This may have a material adverse effect on the Services.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">13. Can your Data Privacy be ensured?</Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              Our Services are built on the Secret Network Blockchain.
              Accordingly, by design, and practically, the records cannot be
              changed or deleted and are said to be ‘immutable’. This may affect
              your ability to exercise your rights such as your right to erasure
              (‘right to be forgotten’), or your rights to object or restrict
              processing of your personal data. Data on the Secret Network
              blockchain cannot be erased and cannot be changed.
            </ListItem>
            <ListItem>
              In order to comply with some of our contractual obligations to
              you, it will be necessary to write certain personal data, such as
              your PriFi Labs address, onto the Secret Network blockchain.
            </ListItem>
            <ListItem>
              The ultimate decisions to (i) transact on the Secret Network
              Blockchain using your PriFi Labs address, as well as (ii) share
              the public key relating to your PriFi Labs address with anyone
              (including us) rests with you.
            </ListItem>
            <ListItem>
              When using the PriFi Labs Interface or Dashboard we may collect
              and process personal data, including your PriFi Labs address,
              Wallet addresses of externally owned accounts or smart contract
              Wallets set as signatories for your PriFi Labs Transactions (the
              “Owners”), Transaction made with the PriFi Labs as well as SCRT
              and Token balance.
            </ListItem>
            <ListItem>
              IF YOU WANT TO ENSURE YOUR PRIVACY RIGHTS ARE FULLY AVAILABLE, YOU
              SHOULD NOT TRANSACT ON THE SECRET NETWORK BLOCKCHAIN AS CERTAIN
              RIGHTS WILL NOT BE FULLY AVAILABLE OR EXERCISABLE BY YOU OR US.
            </ListItem>
            <ListItem>
              For more information please also refer to section 3 of our Privacy
              Policy.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            14. What do you agree, warrant and represent?
          </Heading>
          <Text>
            By using our Services you hereby agree, represent and warrant that:
          </Text>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              You are of legal age in your jurisdiction to use the Services and
              you agree to provide legitimate and lawful documentation proving
              such status if requested or required by us.
            </ListItem>
            <ListItem>
              You are not a citizen, resident, or member of any jurisdiction or
              group that is subject to economic sanctions by Canada, the
              European Union or the United States or any other relevant
              jurisdiction.
            </ListItem>
            <ListItem>
              You do not appear on HMT Sanctions List, the U.S. Treasury
              Department’s Office of Foreign Asset Control’s sanctions lists,
              the U.S. commerce department's consolidated screening list, the EU
              consolidated list of persons, groups or entities subject to EU
              Financial Sanctions, nor do you act on behalf of a person
              sanctioned thereunder.
            </ListItem>
            <ListItem>
              You have read and understood this Agreement and agree to be bound
              by its terms.
            </ListItem>
            <ListItem>
              You do not rely on, and shall have no remedy in respect of, any
              statement, representation, assurance or warranty (whether made
              innocently or negligently) that is not set out in this Agreement.
            </ListItem>
            <ListItem>
              Your usage of our Services is legal under the laws of your
              jurisdiction or under the laws of any other jurisdiction to which
              you may be subject.
            </ListItem>
            <ListItem>
              You won’t use the Services or interact with the Services in a
              manner that violates any law or regulation, including, without
              limitation, any applicable export control laws.
            </ListItem>
            <ListItem>
              You understand the functionality, usage, storage, transmission
              mechanisms and intricacies associated with Tokens (such as SCRT,
              ETH, WETH or DAI) as well as token storage facilities, blockchain
              technology and blockchain-based software systems.
            </ListItem>
            <ListItem>
              You understand that transactions on the Secret Network Blockchain
              are irreversible and may not be erased and that your PriFi Labs
              address and Transactions are displayed permanently and publicly
              and that you relinquish any right of rectification or erasure of
              personal data.
            </ListItem>
            <ListItem>
              You will comply with any applicable tax obligations in your
              jurisdiction arising from your use of the Services.
            </ListItem>
            <ListItem>
              You will not misuse or gain unauthorised access to our Services by
              knowingly introducing viruses, cross-site scripting, Trojan
              horses, worms, time-bombs, keystroke loggers, spyware, adware or
              any other harmful programs or similar computer code designed to
              adversely affect our Services and that in the event you do so or
              otherwise attack our Services, we reserve the right to report any
              such activity to the relevant law enforcement authorities and we
              will cooperate with those authorities as required.
            </ListItem>
            <ListItem>
              You won’t access without authority, interfere with, damage or
              disrupt any part of our Services, any equipment or network on
              which our Services is stored, any software used in the provision
              of our Services or any equipment or network or software owned or
              used by any third party.
            </ListItem>
            <ListItem>
              You won’t use our Services for activities that are unlawful or
              fraudulent or have such purpose or effect or otherwise support any
              activities that breach applicable local, national or international
              law or regulations.
            </ListItem>
            <ListItem>
              You won’t use our Services to store, trade or transmit Tokens that
              are proceeds of criminal or fraudulent activity.
            </ListItem>
            <ListItem>
              You understand that the Services and the underlying Ethereum
              Blockchain are in an early development stage and we accordingly do
              not guarantee an error-free process and give no price or liquidity
              guarantee.
            </ListItem>
            <ListItem>You are using the Services at your own risk.</ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">15. What if you breach this Agreement?</Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              You agree that you will be liable for any losses sustained by us
              as a result of your breach of this Agreement and will compensate
              us in full for any such losses.
            </ListItem>
            <ListItem>
              We reserve the right, at our own expense, to assume the exclusive
              defense and control of any matter otherwise subject to
              indemnification by you pursuant to paragraph 1 of this clause and,
              in such case, you agree to cooperate with us in the defense of
              such matter.
            </ListItem>
            <ListItem>
              The indemnity set out in this clause is in addition to, and not in
              lieu of, any other remedies that may be available to us under
              applicable law.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">16. What about our liability to you?</Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              Nothing in this Agreement shall limit or exclude our liability to
              you:
              <OrderedList ml="4em" mt="0.7em" spacing="0.7em">
                <ListItem>
                  for death or personal injury caused by our negligence;
                </ListItem>
                <ListItem>for fraudulent misrepresentation; or</ListItem>
                <ListItem>
                  for any other liability that, by law, may not be limited or
                  excluded.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              Subject to this, in no event shall we be liable to you for any
              losses, including any loss of Tokens or any indirect or
              consequential losses, or for any loss of profit, revenue,
              contracts, data, goodwill or other similar losses.
            </ListItem>
            <ListItem>
              Any liability we do have for losses you suffer arising from this
              Agreement per 16.1 must be strictly limited to losses that were
              reasonably foreseeable and shall not be in excess of the greater
              of (I) GBP 100 or (II) the amounts paid by you to us, if any, in
              connection with the Services in the 12 month period preceding this
              applicable claim.
            </ListItem>
            <ListItem>
              Where we are operating in conjunction with third parties and/or
              any other third party systems, we are not responsible for any loss
              as a result of such third party activity. If any Transaction is,
              as a result of your actions or those of a third party, mistakenly
              or fraudulently signed for using your Private Keys, we are not
              liable.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            17. What about viruses, bugs and security vulnerabilities?
          </Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              We do not guarantee that our Services will be secure or free from
              bugs, security vulnerabilities or viruses.
            </ListItem>
            <ListItem>
              You are responsible for configuring your information technology
              and computer programmes to access our Services and to use your own
              virus protection software.
            </ListItem>
            <ListItem>
              If you become aware of any exploits, bugs or vulnerabilities,
              please let us know at thierry.sans@prifilabs.com.
            </ListItem>
            <ListItem>
              You must not misuse our Services by knowingly introducing material
              that is malicious or technologically harmful. If you do, your
              right to use our Services will cease immediately.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">18. Can you link to our Interfaces?</Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              You may link to our Interfaces, provided you do so in a way that
              is fair and legal and does not damage our reputation. You must not
              establish a link in such a way as to suggest any form of
              association, approval or endorsement on our part where none
              exists. You must not establish a link to our Services in any
              application that is not owned by or licensed to you.
            </ListItem>
            <ListItem>
              Our Interface must not be framed on any other graphical user
              interface, nor may you create a link to any part of our graphical
              user interface other than the home page https://prifilabs.com. We
              reserve the right to withdraw linking permission without notice.
            </ListItem>
            <ListItem>
              The graphical user interface or application in which you are
              linking must comply in all respects with the content standards set
              out in this Agreement. If you wish to link to or make any use of
              content on our Interface other than that set out above, please
              contact thierry.sans@prifilabs.com.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            19. What if an event outside our control happens that affects our
            Services?
          </Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              We may update and change our Services from time to time. We may
              suspend or withdraw or restrict the availability of all or any
              part of our Services for business, operational or regulatory
              reasons or because of a Force Majeure Event at no notice.
            </ListItem>
            <ListItem>
              A “Force Majeure Event” shall mean any event, circumstance or
              cause beyond our reasonable control, which prevents, hinders or
              delays the provision of our Services or makes their provision
              impossible or onerous, including, without limitation:
              <OrderedList ml="4em" mt="0.7em" spacing="0.7em">
                <ListItem>
                  acts of God, flood, storm, drought, earthquake or other
                  natural disaster;
                </ListItem>
                <ListItem>
                  epidemic or pandemic (for the avoidance of doubt, including
                  the 2020 Coronavirus Pandemic);
                </ListItem>
                <ListItem>
                  terrorist attack, hacking or cyber threats, civil war, civil
                  commotion or riots, war, threat of or preparation for war,
                  armed conflict, imposition of sanctions, embargo, or breaking
                  off of diplomatic relations;
                </ListItem>
                <ListItem>
                  equipment or software malfunction or bugs including network
                  splits or forks or unexpected changes in the Secret Network
                  Blockchain, as well as hacks, phishing attacks, distributed
                  denials of service or any other security attacks;
                </ListItem>
                <ListItem>
                  nuclear, chemical or biological contamination;
                </ListItem>
                <ListItem>
                  any law statutes, ordinances, rules, regulations, judgments,
                  injunctions, orders and decrees or any action taken by a
                  government or public authority, including without limitation
                  imposing a prohibition, or failing to grant a necessary
                  license or consent;
                </ListItem>
                <ListItem>
                  collapse of buildings, breakdown of plant or machinery, fire,
                  explosion or accident; and
                </ListItem>
                <ListItem>strike, industrial action or lockout.</ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              We shall not be liable or responsible to you, or be deemed to have
              defaulted under or breached this Agreement, for any failure or
              delay in the provision of the Services or the performance of this
              Agreement, if and to the extent such failure or delay is caused by
              or results from or is connected to acts beyond our reasonable
              control, including the occurrence of a Force Majeure Event.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            20. Who is responsible for your tax liabilities?
          </Heading>
          <Text>
            You are solely responsible to determine if your use of the Services
            have tax implications for you. By using the Services you agree not
            to hold us liable for any tax liability associated with or arising
            from the operation of the Services or any other action or
            transaction related thereto.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            21. What if a court disagrees with part of this Agreement?
          </Heading>
          <Text>
            Each of the paragraphs of this Agreement operates separately. If any
            court or relevant authority decides that any of them are unlawful,
            the remaining paragraphs will continue to be in full force and
            effect.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            22. What if we do not enforce certain rights under this Agreement?
          </Heading>
          <Text>
            Our failure to exercise or enforce any right or remedy provided
            under this Agreement or by law shall not constitute a waiver of that
            or any other right or remedy, nor shall it prevent or restrict any
            further exercise of that or any other right or remedy.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">23. Do third parties have rights?</Heading>
          <Text>
            Unless it expressly states otherwise, this Agreement does not give
            rise to any third party rights, which may be enforced against us.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">24. Can this Agreement be assigned?</Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              We may transfer our rights and obligations under this Agreement to
              any other party.
            </ListItem>
            <ListItem>
              You shall not be entitled to assign this Agreement to any third
              party without our express prior written consent.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            25. Which clauses of this Agreement survive termination?
          </Heading>
          <Text>
            All covenants, agreements, representations and warranties made in
            this Agreement shall survive your acceptance of this Agreement and
            its termination.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">26. Which laws apply to this agreement?</Heading>
          <Text>
            This Agreement is governed by and construed in accordance with
            Canadian law.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            27. How can you get support for the PriFi Labs Suite and tell us
            about any problems?
          </Heading>
          <Text>
            If you want to learn more about the PriFi Labs or the Service or
            have any problems using them or have any complaints please get in
            touch with us via any of the following channels:
          </Text>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>Email: thierry.sans@prifilabs.com</ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">
            28. How can we resolve disputes and where can you bring legal
            proceedings?
          </Heading>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              We will use our best efforts to resolve through informal, good
              faith negotiations any dispute, claim or controversy relating to
              this Agreement or relating to the breach, termination,
              enforcement, interpretation or validity thereof, including the
              determination of the scope or applicability of the arbitration
              agreement in clause 28.4 (hereinafter “Dispute”).
            </ListItem>
            <ListItem>
              If a potential Dispute arises, you must contact us by sending an
              email to thierry.sans@prifilabs.com so that we can attempt to
              resolve it without resorting to formal dispute resolution.
            </ListItem>
            <ListItem>
              If we are not able to reach an informal resolution within 60 days
              of your email, then you and we may bring proceedings either in
              binding arbitration, if clause 28.4 applies to you, or in the
              courts of Ontario, Canada, if clause 28.4 does not apply to you.
            </ListItem>
            <ListItem>
              IF YOU ARE RESIDING IN THE UNITED STATES OF AMERICA (“USA”), THIS
              CLAUSE 28.4 REQUIRES YOU TO ARBITRATE ALL DISPUTES WITH US AND
              LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.
              <OrderedList ml="4em" mt="0.7em" spacing="0.7em">
                <ListItem>
                  Binding arbitration. Any Dispute shall be referred to and
                  finally determined by binding and confidential arbitration in
                  accordance with the JAMS International Arbitration Rules
                  (“JAMS Rules”), hereby incorporated by reference and available
                  from JAMS’ website at /www.jamsadr.com/.
                </ListItem>
                <ListItem>
                  Federal Arbitration Act. This Agreement affects interstate
                  commerce and the enforceability of this clause 28.4 will be
                  both substantively and procedurally governed by and construed
                  and enforced in accordance with the United States Federal
                  Arbitration Act, 9 U.S.C. §1 et seq. ( “FAA”), to the maximum
                  extent permitted by applicable law.
                </ListItem>
                <ListItem>
                  The Arbitral Process. The arbitral tribunal shall consist of a
                  sole arbitrator. Only as limited by the FAA, this Agreement
                  and the JAMS Rules, the arbitrator, and not any federal, state
                  or local court or agency, shall have exclusive authority to
                  resolve all Disputes and shall be empowered to grant whatever
                  relief would be available in a court under law or in equity.
                  The arbitrator’s award shall be in writing, and binding on the
                  parties and may be entered as a judgment in any court of
                  competent jurisdiction.
                </ListItem>
                <ListItem>
                  Seat. The seat, or place of, of arbitration will be Toronto.
                  The language to be used in the arbitration proceedings shall
                  be English. You agree to submit to the personal jurisdiction
                  of any federal or state court in Toronto, Ontario, in order to
                  compel arbitration, to stay proceedings pending arbitration,
                  or to confirm, modify, vacate or enter judgment on the award
                  entered by the arbitrator. This clause 28.4 shall not preclude
                  parties from seeking provisional remedies in aid of
                  arbitration from a court of applicable jurisdiction.
                </ListItem>
                <ListItem>
                  Class Action Waiver. You and we agree that any arbitration
                  shall be conducted in individual capacity only and not as a
                  class action or other representative action, and you and we
                  expressly waive the right to file a class action or seek
                  relief on a class basis. YOU AND WE AGREE THAT EACH MAY BRING
                  CLAIMS AGAINST THE OTHER ONLY IN INDIVIDUAL CAPACITY, AND NOT
                  AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                  REPRESENTATIVE PROCEEDING. If any court, arbitrator or
                  arbitral tribunal determines that the class action waiver set
                  forth in this paragraph is void or unenforceable for any
                  reason or that an arbitration can proceed on a class basis,
                  then the arbitration provision set forth above shall be deemed
                  null and void in its entirety and the parties shall be deemed
                  to have NOT agreed to arbitrate disputes.
                </ListItem>
                <ListItem>
                  Exception: Litigation of IP and Small Claims Court Claims.
                  Notwithstanding the parties’ decision to resolve all disputes
                  through arbitration, either party may bring an action in any
                  applicable court to protect its intellectual property rights
                  (“intellectual property rights” means patents, copyrights,
                  moral rights, trademarks, and trade secrets, but not privacy
                  or publicity rights). Either party may also seek relief in a
                  small claims court for disputes or claims within the scope of
                  that court’s jurisdiction.
                </ListItem>
                <ListItem>
                  Right to Opt-out. You have the right to opt-out and not be
                  bound by the arbitration and class action waiver provisions
                  set forth above by sending written notice of your decision to
                  opt-out via email to thierry.sans@prifilabs.com. The notice
                  must be sent within 30 days of April 23, 2020 or your first
                  use of our Services, whichever is later, otherwise you shall
                  be bound to arbitrate disputes in accordance with the terms of
                  those paragraphs. If you opt-out of these arbitration
                  provisions, we also will not be bound by them.
                </ListItem>
                <ListItem>
                  Changes. We will provide 60-days’ notice of any changes to
                  this clause.
                </ListItem>
                <ListItem>
                  Changes will become effective on the 60th day, and will apply
                  prospectively only to any claims arising after the 60th day.
                </ListItem>
                <ListItem>
                  Fair Representation. The parties agree that, wherever
                  practicable, they will seek to appoint a fair representation
                  of diverse arbitrators (considering gender, ethnicity and
                  sexual orientation), and will request administering
                  institutions to include a fair representation of diverse
                  candidates on their rosters and list of potential arbitrator
                  appointees.
                </ListItem>
              </OrderedList>
            </ListItem>
            <ListItem>
              You and we agree that the Courts of Gibraltar shall have exclusive
              jurisdiction to settle any Dispute that is not subject to
              arbitration under clause 28.4 and that any Dispute must be
              resolved in accordance with Gibraltar law without regard to its
              conflict of law provisions. You and we further agree that any
              Dispute is personal to you and us and shall be resolved solely
              through individual action, and will not be brought as a
              representative action, group litigation order or any other type of
              class or collective action proceeding.
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">29. Is this all?</Heading>
          <Text>
            This Agreement constitutes the entire agreement between you and us
            in relation to the Agreement’s subject matter. It replaces and
            extinguishes any and all prior agreements, draft agreements,
            arrangements, warranties, statements, assurances, representations
            and undertakings of any nature made by, or on behalf of either of
            us, whether oral or written, public or private, in relation to that
            subject matter.
          </Text>
          <Text>PriFi Labs Inc</Text>
          <Text>135 Edgewood Ave.</Text>
          <Text>Toronto, ON</Text>
          <Text>M4L 3H3, Canada</Text>
        </Box>
      </Box>
    </Container>
  );
};

export default TermsConditions;
