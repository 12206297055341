import { Box, Container, Heading, Link } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { ListItem, OrderedList } from "@chakra-ui/react";
import "./business.css";

const PrivacyPolicy = () => {
  return (
    <Container pb="10em">
      <Heading textAlign="center" size="3xl" py="2em">
        Privacy Policy
      </Heading>

      <Box
        display="flex"
        flexDir="column"
        gap="2em"
        textAlign="left"
        fontSize="lg"
      >
        <Box display="flex" flexDir="column" gap="0.7em">
          <Text color="gray.400">Last updated on Nov, 2022</Text>
          <Text>
            We are delighted that you have chosen to use our App or visit our
            website. We take our data protection responsibilities with the
            utmost seriousness and we have designed our website so that you may
            navigate and use our website without having to provide Personal
            Data.
          </Text>
          <Text>
            This Policy sets out what Personal Data we collect, how we process
            it and how long we retain it. This Policy applies to all of our
            processing activities where we act as a data controller.
          </Text>
          <Text>
            In this policy, "we", "us" and "our" refers to PriFi Labs Inc. a
            company incorporated in Toronto, Canada.
          </Text>
          <Text>
            For more information about us, see the Contact Us section of this
            policy.
          </Text>
          <Text>
            In this Policy, “personal data” means any information relating to
            you as an identified or identifiable natural person (“Data
            Subject”); an identifiable natural person is one who can be
            identified, directly or indirectly, in particular by reference to an
            identifier such as a name, an online identifier or to one or more
            factors specific to your physical, physiological, genetic, mental,
            economic, cultural or social identity.
          </Text>
          <Text>
            In this Policy, “processing” means any operation or set of
            operations which is performed on personal data (as defined in this
            Privacy Policy) or on sets of personal data, whether or not by
            automated means, such as collection, recording, organization,
            structuring, storage, adaptation or alteration, retrieval,
            consultation, use, disclosure by transmission, dissemination or
            otherwise making available, alignment or combination, restriction,
            erasure or destruction.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em">
          <Heading size="xl">Navigating this Policy</Heading>
          <Text>
            If you are viewing this policy online, you can click on the below
            links to jump to the relevant section:
          </Text>
          <OrderedList ml="4em" spacing="0.7em">
            <ListItem>
              <Link href="#box1">Your information and the Blockchain</Link>
            </ListItem>
            <ListItem>
              <Link href="#box2">How We Use Personal Data</Link>
            </ListItem>
            <ListItem>
              <Link href="#box3">Use of Third Party Applications</Link>
            </ListItem>
            <ListItem>
              <Link href="#box4">Sharing Your Personal Data</Link>
            </ListItem>
            <ListItem>
              <Link href="#box5">Transferring Your data outside of the EU</Link>
            </ListItem>
            <ListItem>
              <Link href="#box6">Existence of Automated Decision-making</Link>
            </ListItem>
            <ListItem>
              <Link href="#box7">Data Security</Link>
            </ListItem>
            <ListItem>
              <Link href="#box8">Your Rights as a Data Subject</Link>
            </ListItem>
            <ListItem>
              <Link href="#box9">Storing Personal Data</Link>
            </ListItem>
            <ListItem>
              <Link href="#box10">Changes to this Privacy Policy</Link>
            </ListItem>
            <ListItem>
              <Link href="#box11">Our details</Link>
            </ListItem>
          </OrderedList>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em" id="box1">
          <Heading size="xl">1. Your information and the Blockchain</Heading>
          <Text>
            Blockchain technology, also known as distributed ledger technology
            (or simply ‘DLT’), is at the core of our business. Blockchains are
            decentralized and made up of digitally recorded data in a chain of
            packages called ‘blocks’. The manner in which these blocks are
            linked is chronological, meaning that the data is very difficult to
            alter once recorded. Since the ledger may be distributed all over
            the world (across several ‘nodes’ which usually replicate the
            ledger) this means there is no single person making decisions or
            otherwise administering the system (such as an operator of a cloud
            computing system), and that there is no centralized place where it
            is located either.
          </Text>
          <Text>
            Accordingly, by design, a blockchains records cannot be changed or
            deleted and is said to be ‘immutable’. This may affect your ability
            to exercise your rights such as your right to erasure (‘right to be
            forgotten’), or your rights to object or restrict processing of your
            personal data. Data on the blockchain cannot be erased and cannot be
            changed. Although smart contracts may be used to revoke certain
            access rights, and some content may be made invisible to others, it
            is not deleted.
          </Text>
          <Text>
            In certain circumstances, in order to comply with our contractual
            obligations to you (such as delivery of tokens) it will be necessary
            to write certain personal data, such as your wallet address, onto
            the blockchain; this is done through a smart contract and requires
            you to execute such transactions using your wallet’s private key.
          </Text>
          <Text>
            In most cases ultimate decisions to (i) transact on the blockchain
            using your wallet address, as well as (ii) share the public key
            relating to your wallet address with anyone (including us) rests
            with you.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="2em" id="box2">
          <Heading size="xl">2. How We Use Personal Data</Heading>
          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">3.1. When visiting our website</Heading>
            <Text>
              We may collect and process Personal Data about your use of our
              website. This data may include:
            </Text>
            <OrderedList ml="4em" spacing="0.7em">
              <ListItem>the browser types and versions used;</ListItem>
              <ListItem>
                the operating system used by the accessing system;
              </ListItem>
              <ListItem>
                the website from which an accessing system reaches our website
                (so-called referrers);
              </ListItem>
              <ListItem>behavior: subpage, duration, and revisit</ListItem>
              <ListItem>the date and time of access to our website,</ListItem>
              <ListItem>the Internet protocol address (“IP address”);</ListItem>
              <ListItem>
                the Internet service provider of the accessing system; and
              </ListItem>
              <ListItem>
                any other similar data and information that may be used in the
                event of attacks on our information technology systems.
              </ListItem>
            </OrderedList>
            <Text>
              This data may be processed in order to deliver the content of our
              website correctly, to optimize the content of our website to
              ensure the long-term viability of our information technology
              systems and website technology, and to provide law enforcement
              authorities with the information necessary for criminal
              prosecution in case of a cyber-attack.
            </Text>
            <Text>
              The legal basis for this processing is our legitimate business
              interests, namely monitoring and improving our website and the
              proper protection of our business against risks and your consent
              when agreeing to accept cookies
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">3.2. When using PriFi Labs</Heading>
            <Text>
              When using PriFi Labs, we may collect and process personal data.
              The data will be stored in different instances.
            </Text>
            <OrderedList ml="4em" spacing="0.7em">
              <ListItem>
                On the Blockchain the following data will be stored:
                <OrderedList ml="4em" mt="0.7em" spacing="0.7em">
                  <ListItem>
                    your smart contract address of the PriFi Labs Suite;
                  </ListItem>
                  <ListItem>addresses of externally owned accounts</ListItem>
                  <ListItem>
                    transaction made with the PriFi Labs Suite; and
                  </ListItem>
                  <ListItem>SCRT and token balance.</ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                The data is needed to create the user’s PriFi Labs Suite and
                enable the user to make use of the app. PriFi Labs offers a
                suite of decentralized tools to assist in storing secret data.
                Thus, the externally owned accounts are needed to confirm a
                transaction before they are executed. The legal basis for this
                processing is that it is necessary to fulfill a contract with
                you. The data will be stored on the SCRT Blockchain and will be
                reliant on this blockchain’s technology to maintain secrecy.
              </ListItem>
              <ListItem>
                In our AWS server we may store the following data:
                <OrderedList ml="4em" mt="0.7em" spacing="0.7em">
                  <ListItem>
                    addresses of externally owned accounts; and
                  </ListItem>
                  <ListItem>
                    transaction hashes made with the PriFi Labs Suite.
                  </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                The legal basis for this processing is that it is necessary to
                fulfill a contract with you.
              </ListItem>
              <ListItem>
                Log Data
                <OrderedList ml="4em" mt="0.7em" spacing="0.7em">
                  <ListItem>
                    the Internet protocol address (“IP address”); and
                  </ListItem>
                  <ListItem>transaction id/ Hash. </ListItem>
                </OrderedList>
              </ListItem>
              <ListItem>
                We need this data to be able to debug issues and provide support
                for our application. The legal basis for this processing is that
                it is necessary to fulfill a contract with you.
              </ListItem>
            </OrderedList>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">
              3.3. When Participating in User Experience Research (UXR)
            </Heading>
            <Text>
              When you participate in our user experience research we may
              collect and process some personal data. This data may include:
            </Text>
            <OrderedList ml="4em" spacing="0.7em">
              <ListItem>your name</ListItem>
              <ListItem>your email</ListItem>
              <ListItem>your phone type</ListItem>
              <ListItem>your occupation</ListItem>
              <ListItem>range of managed funds</ListItem>
            </OrderedList>
            <Text>
              In addition, we may take a recording of you while testing the
              PriFi Labs suite for internal and external use. The basis for this
              collection and processing is our legitimate business interest in
              monitoring and improving our services.
            </Text>
            <Text>
              The legal basis for this processing is your consent as provided
              before participating in user experience research.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">
              3.4 When registering for the email notification service
            </Heading>
            <Text>
              We may collect and process Personal Data that you provide to us
              for the purpose of subscribing to our email notification service.
              This data may include:
            </Text>
            <OrderedList ml="4em" spacing="0.7em">
              <ListItem>your email address;</ListItem>
              <ListItem>the date and time of registration;</ListItem>
              <ListItem>your IP address.</ListItem>
            </OrderedList>
            <Text>
              This data is collected and processed for the purpose of sending
              you product updates and announcements related to the PriFi Labs
              suite.
            </Text>
            <Text>
              The legal basis for this processing is your consent as provided in
              the double opt-in confirmation part of our sign-up process. Your
              email address will be stored as long as we have the consent to
              send you a notification email.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">
              3.5 When receiving the email notification
            </Heading>
            <Text>
              If you have subscribed to our email notification service, each
              time you receive an email notification from us, we may collect and
              process Personal Data. This data may include:
            </Text>
            <OrderedList ml="4em" spacing="0.7em">
              <ListItem>the date and time you opened the email;</ListItem>
              <ListItem>
                what (if any) links or URLs you accessed from our newsletter;
              </ListItem>
              <ListItem>the location it was accessed from.</ListItem>
            </OrderedList>
            <Text>
              This data is collected and processed for the purpose of improving
              the content of our email notification service.
            </Text>
            <Text>
              The legal basis for this processing is your consent as provided in
              the double opt-in confirmation part of our sign-up process.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">3.6 Other uses of your Personal Data</Heading>
            <Text>
              We may process any of your Personal Data where it is necessary to
              establish, exercise, or defend legal claims. The legal basis for
              this processing is our legitimate interests, namely the protection
              and assertion of our legal rights, your legal rights and the legal
              rights of others.
            </Text>
            <Text>
              Further, we may process your Personal data where such processing
              is necessary in order for us to comply with a legal obligation to
              which we are subject. The legal basis for this processing is our
              legitimate interests, namely the protection and assertion of our
              legal rights.
            </Text>
          </Box>
        </Box>

        <Box display="flex" flexDir="column" gap="2em" id="box3">
          <Heading size="xl">3. Use of Third Party Applications</Heading>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">4.1. Blockchain</Heading>
            <Text>
              When using the PriFi Labs suite, your smart contract address, the
              transactions made with the suite’s smart contracts, addresses of
              externally owned accounts and SCRT balances and token balances
              will be stored on the Blockchain. See section 2 of this Policy
            </Text>
            <Text>
              The information will be recorded on the SCRT Blockchain with
              public privacy, this is part of the nature of the blockchain. If
              you are new to this field, we highly recommend informing yourself
              about the blockchain technology before using our services.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">4.2. AWS Webserver</Heading>
            <Text>
              We use the AWS EC2 to store log and database data as described in
              section 3.2
            </Text>
            <Text>
              For further information and the applicable data protection
              provisions of AWS EC2 please visit{" "}
              <Link
                href="https://docs.aws.amazon.com/ec2/index.html"
                color="teal.500"
                isExternal={true}
              >
                https://docs.aws.amazon.com/ec2/index.html
              </Link>
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">4.3 AWS Simple Email Service (SES)</Heading>
            <Text>
              We use AWS Simple Email Service (SES) for our email notification
              service to subscribers. AWS SES allows us to prepare customized
              Emails and manage our subscribers.
            </Text>
            <Text>
              We do not store any information collected by AWS. AWS’s data
              protection policy is available at{" "}
              <Link
                href="https://docs.aws.amazon.com/ses/latest/dg/data-protection.html"
                color="teal.500"
                isExternal={true}
              >
                https://docs.aws.amazon.com/ses/latest/dg/data-protection.html
              </Link>
            </Text>
            <Text>
              AWS SES’s purpose and function is further explained under the
              following link:{" "}
              <Link
                href="https://aws.amazon.com/ses/"
                color="teal.500"
                isExternal={true}
              >
                https://aws.amazon.com/ses/
              </Link>
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">4.4. Transmitting Social Media Links</Heading>
            <Text>
              At the end of our website we link to our social media profiles.
              Those services might also collect Personal Data. Please refer to
              their privacy policies for more information.
            </Text>
            <Text>
              Facebook:{" "}
              <Link
                href="https://www.facebook.com/policy.php"
                color="teal.500"
                isExternal={true}
              >
                https://www.facebook.com/policy.php
              </Link>
            </Text>
            <Text>
              Twitter:{" "}
              <Link
                href="https://twitter.com/de/privacy"
                color="teal.500"
                isExternal={true}
              >
                https://twitter.com/de/privacy
              </Link>
            </Text>
            <Text>
              Reddit:{" "}
              <Link
                href="https://www.redditinc.com/policies/privacy-policy"
                color="teal.500"
                isExternal={true}
              >
                https://www.redditinc.com/policies/privacy-policy
              </Link>
            </Text>
            <Text>
              Medium:{" "}
              <Link
                href="https://medium.com/policy/medium-privacy-policy-f03bf92035c9"
                color="teal.500"
                isExternal={true}
              >
                https://medium.com/policy/medium-privacy-policy-f03bf92035c9
              </Link>
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">4.5 Telegram</Heading>
            <Text>
              In order to provide user support we created a group on Telegram (
              <Link
                href="https://telegram.org"
                color="teal.500"
                isExternal={true}
              >
                https://telegram.org
              </Link>
              ) to facilitate the resolution of any questions and concerns
              should these arise.
            </Text>
            <Text>
              By accepting this Privacy Policy, you are deemed to consent to
              providing the following Personal Data to persons looking to
              resolve any dispute:
            </Text>
            <OrderedList ml="4em" spacing="0.7em">
              <ListItem>Name and surname;</ListItem>
              <ListItem>Used wallet address; </ListItem>
              <ListItem>Detailed enquiry description;</ListItem>
              <ListItem>The date and time that the issue arose;</ListItem>
              <ListItem>The outcome sought.</ListItem>
            </OrderedList>
            <Text>
              Please note that Telegram may use and/or collect your Personal
              Data. Thus, we recommend to view Telegram’s Privacy Policy at the
              following link:{" "}
              <Link
                href="https://telegram.org/privacy"
                color="teal.500"
                isExternal={true}
              >
                https://telegram.org/privacy
              </Link>
              , on a periodical basis.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">4.6 Discord</Heading>
            <Text>
              In order to provide user support we created a group on Discord ({" "}
              <Link
                href="https://discord.com/new"
                color="teal.500"
                isExternal={true}
              >
                https://discord.com/new
              </Link>
              ) to facilitate the resolution of any questions and concerns
              should these arise.
            </Text>
            <Text>
              By accepting this Privacy Policy, you are deemed to consent to
              providing the following Personal Data to persons looking to
              resolve any dispute:
            </Text>
            <OrderedList ml="4em" spacing="0.7em">
              <ListItem>Used wallet address;</ListItem>
              <ListItem>Detailed enquiry description;</ListItem>
              <ListItem>The date and time that the issue arose;</ListItem>
              <ListItem>The outcome sought.</ListItem>
            </OrderedList>
            <Text>
              Please note that Discord may use and/or collect your Personal
              Data. Thus, we recommend to view Discord’s Privacy Policy at the
              following link:{" "}
              <Link
                href="https://discord.com/new/privacy"
                color="teal.500"
                isExternal={true}
              >
                https://discord.com/new/privacy
              </Link>
              , on a periodical basis.
            </Text>
          </Box>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em" id="box4">
          <Heading size="xl">4. Sharing Your Personal Data</Heading>
          <Text>
            We may pass your information to our Business Partners,
            administration centers, third party service providers, agents,
            subcontractors and other associated organizations for the purposes
            of completing tasks and providing our services to you.
          </Text>
          <Text>
            In addition, when we use any other third-party service providers, we
            will disclose only the personal information that is necessary to
            deliver the service required and we will ensure that they keep your
            information secure and not use it for their own direct marketing
            purposes. In addition, we may transfer your personal information to
            a third party as part of a sale of some, or all, of our business and
            assets or as part of any business restructuring or reorganization,
            or if we are under a duty to disclose or share your personal data in
            order to comply with any legal obligation. However, we will take
            steps to ensure that your privacy rights continue to be protected.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em" id="box5">
          <Heading size="xl">
            5. Transferring Your data outside of the EU
          </Heading>
          <Text>
            The data mentioned in section 3.2b) and c) will be stored in our AWS
            EC2, which is based in the US. AWS is certified under the EU-US
            Privacy Shield.
          </Text>
          <Text>
            Intercom is based in the USA, however has self-certified to the
            EU-U.S. and Swiss-U.S. Privacy Shield.
          </Text>
          <Text>
            However, when interacting with the blockchain, as explained above in
            this Policy, the SCRT blockchain is a global decentralized public
            privacy network and accordingly any personal data written onto the
            blockchain may be transferred and stored across the globe.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em" id="box6">
          <Heading size="xl">6. Existence of Automated Decision-making</Heading>
          <Text>
            We do not use automatic decision-making or profiling when processing
            Personal Data.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em" id="box7">
          <Heading size="xl">7. Data Security</Heading>
          <Text>
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorized way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only
            process your personal data on our instructions and they are subject
            to a duty of confidentiality.
          </Text>
          <Text>
            We have put in place procedures to deal with any suspected personal
            data breach and will notify you and any applicable regulator of a
            breach where we are legally required to do so.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="2em" id="box8">
          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="xl">8. Your Rights as a Data Subject</Heading>
            <Text>
              You have certain rights under applicable legislation, and in
              particular under Regulation EU 2016/679 (General Data Protection
              Regulation or ‘GDPR’). We explain these below. You can find out
              more about the GDPR and your rights by accessing the European
              Commission’s website.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">Right Information and access</Heading>
            <Text>
              You have a right to be informed about the processing of your
              personal data (and if you did not give it to us, information as to
              the source) and this Privacy Policy intends to provide the
              information. Of course, if you have any further questions you can
              contact us on the above details.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">Right to rectification</Heading>
            <Text>
              You have the right to have any inaccurate personal information
              about you rectified and to have any incomplete personal
              information about you completed. You may also request that we
              restrict the processing of that information. The accuracy of your
              information is important to us. If you do not want us to use your
              Personal Information in the manner set out in this Privacy Policy,
              or need to advise us of any changes to your personal information,
              or would like any more information about the way in which we
              collect and use your Personal Information, please contact us at
              the above details.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">
              Right to erasure (right to be ‘forgotten’)
            </Heading>
            <Text>
              You have the general right to request the erasure of your personal
              information in the following circumstances:
            </Text>
            <OrderedList ml="4em" spacing="0.7em">
              <ListItem>
                the personal information is no longer necessary for the purpose
                for which it was collected;
              </ListItem>
              <ListItem>
                you withdraw your consent to consent based processing and no
                other legal justification for processing applies;
              </ListItem>
              <ListItem>
                you object to processing for direct marketing purposes;
              </ListItem>
              <ListItem>
                we unlawfully processed your personal information; and
              </ListItem>
              <ListItem>
                erasure is required to comply with a legal obligation that
                applies to us.
              </ListItem>
            </OrderedList>
            <Text>
              However, when interacting with the blockchain we may not be able
              to ensure that your personal data is deleted. This is because the
              blockchain is a public decentralized network and blockchain
              technology does not generally allow for data to be deleted and
              your right to erasure may not be able to be fully enforced. In
              these circumstances we will only be able to ensure that all
              personal data that is held by us is permanently deleted.
            </Text>
            <Text>
              We will proceed to comply with an erasure request without delay
              unless continued retention is necessary for:
            </Text>
            <OrderedList ml="4em" spacing="0.7em">
              <ListItem>
                Exercising the right of freedom of expression and information;
              </ListItem>
              <ListItem>
                Complying with a legal obligation under EU or other applicable
                law;
              </ListItem>
              <ListItem>
                The performance of a task carried out in the public interest;
              </ListItem>
              <ListItem>
                Archiving purposes in the public interest, scientific or
                historical research purposes, or statistical purposes, under
                certain circumstances; and/or
              </ListItem>
              <ListItem>
                The establishment, exercise, or defense of legal claims.
              </ListItem>
            </OrderedList>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">
              Right to restrict processing and right to object to processing
            </Heading>
            <Text>
              You have a right to restrict processing of your personal
              information, such as where:
            </Text>
            <OrderedList ml="4em" spacing="0.7em">
              <ListItem>
                you contest the accuracy of the personal information;
              </ListItem>
              <ListItem>
                where processing is unlawful you may request, instead of
                requesting erasure, that we restrict the use of the unlawfully
                processed personal information;
              </ListItem>
              <ListItem>
                we no longer need to process your personal information but need
                to retain your information for the establishment, exercise, or
                defense of legal claims.
              </ListItem>
            </OrderedList>
            <Text>
              You also have the right to object to processing of your personal
              information under certain circumstances, such as where the
              processing is based on your consent and you withdraw that consent.
              This may impact the services we can provide and we will explain
              this to you if you decide to exercise this right.
            </Text>
            <Text>
              However, when interacting with the blockchain, as it is a public
              decentralized network, we will likely not be able to prevent
              external parties from processing any personal data which has been
              written onto the blockchain. In these circumstances we will use
              our reasonable endeavors to ensure that all processing of personal
              data held by us is restricted, notwithstanding this, your right to
              restrict processing may not be able to be fully enforced.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">Right to data portability</Heading>
            <Text>
              Where the legal basis for our processing is your consent or the
              processing is necessary for the performance of a contract to which
              you are party or in order to take steps at your request prior to
              entering into a contract, you have a right to receive the personal
              information you provided to us in a structured, commonly used and
              machine-readable format, or ask us to send it to another person.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">
              Right to freedom from automated decision-making
            </Heading>
            <Text>
              As explained above, we do not use automated decision-making, but
              where any automated decision-making takes place, you have the
              right in this case to express your point of view and to contest
              the decision, as well as request that decisions based on automated
              processing concerning you or significantly affecting you and based
              on your personal data are made by natural persons, not only by
              computers.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">
              Right to object to direct marketing (‘opting out’)
            </Heading>
            <Text>
              You have a choice about whether or not you wish to receive
              information from us. We will not contact you for marketing
              purposes unless:
            </Text>
            <OrderedList ml="4em" spacing="0.7em">
              <ListItem>
                you have a business relationship with us, and we rely on our
                legitimate interests as the lawful basis for processing (as
                described above)
              </ListItem>
              <ListItem>
                you have otherwise given your prior consent (such as when you
                download one of our guides)
              </ListItem>
            </OrderedList>
            <Text>
              You can change your marketing preferences at any time by
              contacting us on the above details. On each and every marketing
              communication, we will always provide the option for you to
              exercise your right to object to the processing of your personal
              data for marketing purposes (known as ‘opting-out’) by clicking on
              the ‘unsubscribe’ button on our marketing emails or choosing a
              similar opt-out option on any forms we use to collect your data.
              You may also opt-out at any time by contacting us on the below
              details.
            </Text>
            <Text>
              Please note that any administrative or service-related
              communications (to offer our services, or notify you of an update
              to this Privacy Policy or applicable terms of business, etc.) will
              solely be directed at our clients or business partners, and such
              communications generally do not offer an option to unsubscribe as
              they are necessary to provide the services requested. Therefore,
              please be aware that your ability to opt-out from receiving
              marketing and promotional materials does not change our right to
              contact you regarding your use of our website or as part of a
              contractual relationship we may have with you.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">Right to request access</Heading>
            <Text>
              You also have a right to access information we hold about you. We
              are happy to provide you with details of your Personal Information
              that we hold or process. To protect your personal information, we
              follow set storage and disclosure procedures, which mean that we
              will require proof of identity from you prior to disclosing such
              information. You can exercise this right at any time by contacting
              us on the above details.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">Right to withdraw consent</Heading>
            <Text>
              Where the legal basis for processing your personal information is
              your consent, you have the right to withdraw that consent at any
              time by contacting us on the above details.
            </Text>
          </Box>

          <Box display="flex" flexDir="column" gap="0.7em">
            <Heading size="md">
              Raising a complaint about how we have handled your personal data
            </Heading>
            <Text>
              If you wish to raise a complaint on how we have handled your
              personal data, you can contact us as set out above and we will
              then investigate the matter.
            </Text>
          </Box>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em" id="box9">
          <Heading size="xl">9. Storing Personal Data</Heading>
          <Text>
            We retain your information only for as long as is necessary for the
            purposes for which we process the information as set out in this
            policy.
          </Text>
          <Text>
            However, we may retain your Personal Data for a longer period of
            time where such retention is necessary for compliance with a legal
            obligation to which we are subject, or in order to protect your
            vital interests or the vital interests of another natural person.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em" id="box10">
          <Heading size="xl">10. Changes to this Privacy Policy</Heading>
          <Text>
            We may make changes to this Policy from time to time. Where we do
            so, we will notify those who have a business relationship with us or
            who are subscribed to our emailing lists directly of the changes,
            and change the ‘Last updated’ date above. We encourage you to review
            the Policy whenever you access or use our website to stay informed
            about our information practices and the choices available to you. If
            you do not agree to the revised Policy, you should discontinue your
            use of this website.
          </Text>
        </Box>

        <Box display="flex" flexDir="column" gap="0.7em" id="box11">
          <Heading size="xl">11. Our details</Heading>
          <Text>This website is owned and operated by PriFi Labs Inc.</Text>
        </Box>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
