import { useEffect } from "react";

import { Box } from "@chakra-ui/react";
import PageRoutes from "./routes/Routes";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import ReactGA from "react-ga";

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_TRACKING!);
}

export const App = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <Box textAlign="center" fontSize="xl" bg="#F8F9FA">
      <PageRoutes />
    </Box>
  );
};
