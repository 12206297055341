import { useState } from "react";
import { Flex, Container } from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import Sidebar from "../../components/Sidebar/Sidebar";
import BackupStep0 from "../../components/BackupSteps/BackupStep0";
import BackupStep1 from "../../components/BackupSteps/BackupStep1";
import BackupStep2 from "../../components/BackupSteps/BackupStep2";
import BackupStep3 from "../../components/BackupSteps/BackupStep3";
import BackupSuccess from "../../components/BackupSteps/BackupSuccess";
import Footer from "../../components/common/Footer/Footer";

import ReactGA from "react-ga";

export const DashboardBackup = (): JSX.Element => {
  const currentPath = window.location.pathname;
  const [, setDisableNext] = useState<boolean | undefined>(true);
  const { nextStep, setStep, activeStep } = useSteps({
    initialStep: 0,
  });

  function next(label: string) {
    return function () {
      if (process.env.REACT_APP_ENV === "production") {
        ReactGA.event({
          category: "Completed",
          action: "Backup",
          label: label,
        });
      }
      nextStep();
    };
  }

  return (
    <>
      <Flex direction="column" flex="1">
        <Sidebar currentPath={currentPath}>
          <Container
            padding={{ base: "4", md: "12" }}
            maxW="5xl"
            bg="white"
            boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
            borderRadius="15px"
            marginBottom="10px"
          >
            <Flex flexDir="column" width="100%">
              <Steps
                activeStep={activeStep}
                onClickStep={(step: number) => {
                  if (activeStep > step) setStep(step);
                }}
                colorScheme="teal"
                variant="circles-alt"
              >
                <Step label="Start" key="BackupStart">
                  <BackupStep0 nextStep={next("step1")} />
                </Step>
                <Step label="Register" key="BackupAddEmail">
                  <BackupStep1
                    setDisableNext={setDisableNext}
                    nextStep={next("step2")}
                  />
                </Step>
                <Step label="Verify" key="BackupVerifyEmail">
                  <BackupStep2
                    setDisableNext={setDisableNext}
                    nextStep={next("step3")}
                  />
                </Step>
                <Step label="Passphrase" key="BackupSecretText">
                  <BackupStep3
                    setDisableNext={setDisableNext}
                    nextStep={next("step4")}
                  />
                </Step>
                <Step label={"Done"} key="BackupSuccess">
                  <BackupSuccess />
                </Step>
              </Steps>
            </Flex>
          </Container>
        </Sidebar>
        <Footer></Footer>
      </Flex>
    </>
  );
};

export default DashboardBackup;
