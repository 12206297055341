import React from "react";
import { Image } from "@chakra-ui/react";

export const LogoText = () => {
  return (
    <Image
      src={require("../../../assets/thelockerlogo.png")}
      width="48"
      alt="logo"
      marginLeft="-2"
    ></Image>
  );
};
