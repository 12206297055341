import { Flex, Container } from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import Sidebar from "../../components/Sidebar/Sidebar";
import ApproveStep0 from "../../components/ApproveSteps/ApproveStep0";
import ApproveStep1 from "../../components/ApproveSteps/ApproveStep1";
import ApproveStep2 from "../../components/ApproveSteps/ApproveStep2";
import ApproveSuccess from "../../components/ApproveSteps/ApproveSuccess";
import Footer from "../../components/common/Footer/Footer";

import ReactGA from "react-ga";

export const DashboardApprove = (): JSX.Element => {
  const currentPath = window.location.pathname;
  const { nextStep, setStep, activeStep } = useSteps({
    initialStep: 0,
  });

  function next(label: string) {
    return function () {
      if (process.env.REACT_APP_ENV === "production") {
        ReactGA.event({
          category: "Completed",
          action: "Approve",
          label: label,
        });
      }
      nextStep();
    };
  }

  return (
    <div>
      <Sidebar currentPath={currentPath}>
        <Container
          padding={{ base: "4", md: "12" }}
          maxW="5xl"
          bg="white"
          boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
          borderRadius="15px"
        >
          <Flex flexDir="column" width="100%">
            <Steps
              activeStep={activeStep}
              onClickStep={(step: number) => {
                if (activeStep > step) setStep(step);
              }}
              colorScheme="teal"
              variant="circles-alt"
            >
              <Step label="Start" key="ApproveStart">
                <ApproveStep0 nextStep={next("step1")} />
              </Step>
              <Step label="Enter Code" key="ApproveVerify">
                <ApproveStep1 nextStep={next("step2")} />
              </Step>
              <Step label="Confirm Address" key="ApproveConfirm">
                <ApproveStep2 nextStep={next("step3")} setStep={setStep} />
              </Step>
              <Step label="Success" key="ApproveSuccess">
                <ApproveSuccess setStep={setStep} />
              </Step>
            </Steps>
          </Flex>
        </Container>
      </Sidebar>
      <Footer></Footer>
    </div>
  );
};

export default DashboardApprove;
