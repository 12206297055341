import { RadioGroup, Box } from "@chakra-ui/react";
import { useUser } from "../../../contexts/UserContext";
import ShareDivider from "./ShareDivider";
import ShareRecover from "./ShareRecover";
import { ShareListProps } from "./types";

const ShareListRecover = (props: ShareListProps) => {
  const { userInfo, setUserInfo } = useUser();

  async function selectShare(value: string) {
    if (value) {
      const array = value.split(",");
      setUserInfo({ ...userInfo, selected: array[0], email: array[1] });
    }
    props.setValue(value);
  }

  return (
    <RadioGroup
      onChange={(e) => selectShare(e)}
      value={props.value}
      colorScheme="teal"
      display="flex"
      flexDirection="column"
    >
      {props.shareList.map((option, i) => (
        <Box key={i}>
          {i === 0 && <ShareDivider />}
          <ShareRecover share={option} />
          <ShareDivider />
        </Box>
      ))}
    </RadioGroup>
  );
};

export default ShareListRecover;
