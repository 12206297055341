import { Container, FormLabel, Text, VStack, Button } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useBackupContract } from "../../contexts/BackupContractContext";
import { useUser } from "../../contexts/UserContext";

interface SuccessProps {
  setStep: (step: number) => void;
}

const ApproveSuccess = (props: SuccessProps) => {
  const { backupContract, setBackupContract } = useBackupContract();
  const { userInfo, setUserInfo } = useUser();

  function startNewApproval() {
    setUserInfo({ ...userInfo, recipient: "" });
    setBackupContract({
      ...backupContract,
      tid: undefined,
      nonce: undefined,
      uuid: undefined,
      addr: undefined,
    });
    props.setStep(0);
  }

  return (
    <div>
      <Container>
        <VStack marginBottom="10">
          <Text padding="10">
            <b>Step 4: Transfer has been approved! </b>{" "}
            <CheckCircleIcon marginLeft="2" marginBottom="1" color="teal.400" />
          </Text>
          <FormLabel marginBottom="5">
            Congratulations! Your transaction has been approved!
          </FormLabel>
          <Button
            onClick={() => startNewApproval()}
            width="12em"
            colorScheme="teal"
          >
            Start a New Approval
          </Button>
        </VStack>
      </Container>
    </div>
  );
};

export default ApproveSuccess;
