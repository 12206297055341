import {
  Container,
  Text,
  Box,
  OrderedList,
  ListItem,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";

import ConnectWalletModal from "../common/ConnectWallet/ConnectWalletModal";
import { useWalletModal } from "../../contexts/WalletModalContext";

type StepType = {
  nextStep: () => void;
};

const BackupStep0 = (props: StepType) => {
  const { navModalHelper } = useWalletModal();

  function next() {
    return navModalHelper(() => props.nextStep());
  }

  return (
    <Container>
      <ConnectWalletModal />
      <Box
        fontSize={useBreakpointValue({ base: "sm", md: "md" }, { ssr: false })}
        color="muted"
        alignItems={useBreakpointValue(
          {
            base: "center",
            md: "left",
          },
          { ssr: false }
        )}
        textAlign={useBreakpointValue(
          {
            base: "center",
            md: "left",
          },
          { ssr: false }
        )}
        padding={{ base: "0", md: "12" }}
      >
        <Text marginTop={2} fontSize="md">
          To back up a mnemonic passphrase, you will need:
        </Text>
        <OrderedList marginTop={2} textAlign="left">
          <ListItem>
            <b>A Kepler Wallet with SCRT.</b> We recommend that you provision
            a new wallet that you can throwaway after the backup process. You
            won't need that wallet when you recover your passphrase.
          </ListItem>
          <ListItem>
            <b>A valid email address.</b> The application will send a
            verification code to that email address.
          </ListItem>
          <ListItem>
            <b>Your passphrase.</b> Or any other piece of text your want to
            backup confidentialy.
          </ListItem>
          <ListItem>
            <b>One or more Secret wallet addresses of people you trust.</b>{" "}
            These people will receive pieces of your passphrase lock that we
            call "shares". When the time comes to recover your passphrase, you
            will need to get some of those shares back before starting the
            recovery process.
          </ListItem>
        </OrderedList>
      </Box>
      <Button
        bg="orange.400"
        color="white"
        onClick={next}
        marginTop={4}
        size={useBreakpointValue({ base: "sm", md: "md" }, { ssr: false })}
      >
        Let's start
      </Button>
    </Container>
  );
};

export default BackupStep0;
