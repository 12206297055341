import React from "react";
import {
  Slider,
  HStack,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  FormLabel,
} from "@chakra-ui/react";
import { useUser } from "../../../contexts/UserContext";

const SliderShares = () => {
  const { userInfo, setUserInfo } = useUser();
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleChange = (value: number) => {
    setUserInfo({
      ...userInfo,
      supply: value,
      threshold: value < userInfo.threshold ? value : userInfo.threshold,
    });
  };

  return (
    <HStack padding="2">
      <FormLabel padding="2">{userInfo.supply}</FormLabel>
      <Slider
        id="slider"
        min={1}
        max={10}
        defaultValue={1}
        value={userInfo.supply}
        colorScheme="teal"
        onChange={(v) => handleChange(v)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <Tooltip
          hasArrow
          bg="teal.500"
          color="white"
          placement="top"
          isOpen={showTooltip}
          label={userInfo.supply}
        >
          <SliderThumb />
        </Tooltip>
      </Slider>
    </HStack>
  );
};

export default SliderShares;
