import { useDisclosure } from "@chakra-ui/react";
import React, { createContext, useState } from "react";
import { useWallet } from "./WalletContext";

type ContextType = {
  isModalOpen: boolean;
  onModalOpen: () => void;
  onModalClose: () => void;
  whereTo: () => void;
  setWhereTo: React.Dispatch<React.SetStateAction<() => void>>;
  navModalHelper: (nav: () => void) => void;
};

const Context = createContext<ContextType>({} as ContextType);

const WalletModalProvider = (props: any) => {
  const { keplr } = useWallet();
  const [whereTo, setWhereTo] = useState(() => () => {});
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  function navModalHelper(nav: () => void) {
    if (!keplr.secretJS) {
      setWhereTo(() => nav);
      onModalOpen();
    } else {
      nav();
    }
  }

  return (
    <Context.Provider
      value={{
        isModalOpen,
        onModalOpen,
        onModalClose,
        whereTo,
        setWhereTo,
        navModalHelper,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default WalletModalProvider;

export const useWalletModal = () => React.useContext(Context);
