import { Button, useBreakpointValue } from "@chakra-ui/react";
import { useWallet } from "../../../contexts/WalletContext";
import { useWalletModal } from "../../../contexts/WalletModalContext";
import { trimSecret } from "../../../utils/utils";

const ConnectWalletButton = (props: { callback?: () => void }) => {
  const { keplr, keplrSetup } = useWallet();
  const { onModalClose } = useWalletModal();

  const handleConnectWallet = async () => {
    await keplrSetup();
  };

  const getButtonText = (): string => {
    if (!window.keplr) {
      return "Install Keplr to Continue";
    } else if (!keplr.secretJS) {
      return "Connect Wallet";
    } else {
      return "Connected [" + trimSecret(keplr.secretJS.address + "]");
    }
  };

  const getButtonTextMobile = (): string => {
    if (!window.keplr) {
      return "Install";
    } else if (!keplr.secretJS) {
      return "Connect";
    } else {
      return "Connected";
    }
  };

  return (
    <Button
      size={{ base: "sm", md: "md" }}
      bg="teal.500"
      color="white"
      paddingInline="7"
      onClick={async () => {
        await handleConnectWallet();
        props.callback?.();
        onModalClose();
      }}
    >
      {useBreakpointValue(
        { base: getButtonTextMobile(), sm: getButtonText() },
        { ssr: false }
      )}
    </Button>
  );
};

export default ConnectWalletButton;
