import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Button,
  useToast,
  Container,
  Text,
  Switch,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useUser } from "../../contexts/UserContext";
import { useBackupContract } from "../../contexts/BackupContractContext";
import TransactionHash from "../common/TransactionHash/TransactionHash";
import { ToolTipQuestion } from "../common/LabelTooltip/LabelTooltip";
import { useWallet } from "../../contexts/WalletContext";

type StepType = {
  setDisableNext: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  nextStep: () => void;
};

const RecoverStep3 = (props: StepType) => {
  const { keplr } = useWallet();
  const { userInfo } = useUser();
  const { backupContract, setBackupContract } = useBackupContract();

  const [isFinalSubmitting, setisFinalSubmitting] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const toast = useToast();

  async function transferFinal(event: React.FormEvent) {
    setisFinalSubmitting(true);
    event.preventDefault();
    if (keplr.backupClient && keplr.secretJS) {
      const { tid, nonce, key } = backupContract;
      if (!key || !nonce || !tid) {
        throw new Error("Fatal: Some prerequisite data is undefined");
      }
      try {
        const { passphrase, hash } = await keplr.backupClient.confirmRecovery(
          tid,
          nonce,
          key,
          isDelete
        );
        toast({
          title: "Step 4: Successfully Recovered.",
          description: TransactionHash(hash),
          position: "bottom-right",
          status: "success",
          duration: null,
          isClosable: true,
        });
        setBackupContract({
          ...backupContract,
          hash,
          passphrase,
        });
        props.nextStep();
      } catch (err) {
        toast({
          title: "Step 4: Failed to Recover.",
          description: TransactionHash(""),
          position: "bottom-right",
          status: "error",
          duration: null,
          isClosable: true,
        });
      }
    }
    setisFinalSubmitting(false);
  }

  return (
    <div>
      <Container>
        <Text padding={{ base: "4", md: "10" }}>
          <b>Step 4: Confirm Recovery</b>
        </Text>
        <Text
          fontSize={useBreakpointValue(
            { base: "sm", md: "md" },
            { ssr: false }
          )}
          color="muted"
          alignItems={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          textAlign={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          paddingBottom={4}
        >
          Would you like to confirm the recovery of passphrase id{" "}
          <b>{userInfo.selected}</b> for <b>{userInfo.email}</b>.
        </Text>
        <form onSubmit={transferFinal}>
          <FormControl mb="20">
            <Box display="flex" alignItems="center" gap="10px" mt="20px">
              <FormLabel htmlFor="email-alerts" m="0">
                Delete?
              </FormLabel>
              <Switch
                id="email-alerts"
                colorScheme="orange"
                onChange={(e) => setIsDelete(e.target.checked)}
              />
              <ToolTipQuestion text="Passphrase and all shares will be permanently deleted from the smart contract upon successful recovery." />
            </Box>

            <Button
              bg="orange.400"
              color="white"
              type="submit"
              mt="10"
              isLoading={isFinalSubmitting}
            >
              Confirm Recovery
            </Button>
          </FormControl>
        </form>
      </Container>
    </div>
  );
};

export default RecoverStep3;
