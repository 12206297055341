import React, { useState } from "react";
import {
  FormControl,
  useToast,
  Input,
  Button,
  Container,
  HStack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useBackupContract } from "../../contexts/BackupContractContext";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { useWallet } from "../../contexts/WalletContext";

type StepType = {
  nextStep: () => void;
};

const ApproveStep1 = (props: StepType) => {
  const { keplr } = useWallet();
  const { backupContract, setBackupContract } = useBackupContract();
  const [valid, setValid] = useState<boolean | undefined>();
  const [code, setCode] = useState("");

  const [isCodeSubmitting, setIsCodeSubmitting] = useState<boolean>(false);

  function isValidVerificationCode(verificationCode: string) {
    return /....../.test(verificationCode);
  }

  const handleChangeCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isValidVerificationCode(event.target.value)) {
      setValid(true);
    } else {
      setValid(false);
    }
    setCode(event.target.value);
  };

  const toast = useToast();

  async function transfer(event: React.FormEvent) {
    setIsCodeSubmitting(true);
    event.preventDefault();
    if (keplr.backupClient) {
      try {
        const { tid, nonce } = await keplr.backupClient.checkCodeTransfer(code);
        const { uuid, addr } = await keplr.backupClient.getTransferInfo(
          tid,
          nonce
        );
        toast({
          title: "Step 2: Successfully Verified Approval Request.",
          description: "Proceed to the next step.",
          position: "bottom-right",
          status: "success",
          duration: null,
          isClosable: true,
        });
        setBackupContract({
          ...backupContract,
          tid,
          nonce,
          uuid,
          addr,
        });
        props.nextStep();
      } catch (err) {
        toast({
          title: "Step 2: Failed to Verify Approval Request",
          description: "Please try again.",
          position: "bottom-right",
          status: "error",
          duration: null,
          isClosable: true,
        });
      }
    }
    setIsCodeSubmitting(false);
  }

  return (
    <div>
      <Container>
        <Text padding="10">
          <b>Step 2: Verify Approval Code</b>
        </Text>
        <Text
          fontSize={useBreakpointValue(
            { base: "sm", md: "md" },
            { ssr: false }
          )}
          color="muted"
          alignItems={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          textAlign={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          paddingBottom={4}
        >
          Please enter the verification code received by email:
        </Text>
        <form onSubmit={transfer}>
          <FormControl marginBottom="20">
            <HStack>
              <Input
                value={code}
                required
                onChange={(e) => handleChangeCode(e)}
              />
              {valid ? (
                <CheckCircleIcon color="teal.400" />
              ) : (
                <WarningIcon color="orange.300" />
              )}
              <Button
                bg="orange.400"
                color="white"
                type="submit"
                isLoading={isCodeSubmitting}
                isDisabled={!valid}
                marginTop="10"
              >
                Verify
              </Button>
            </HStack>
          </FormControl>
        </form>
      </Container>
    </div>
  );
};

export default ApproveStep1;
