import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Container,
  VStack,
  useToast,
  Button,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useUser } from "../../contexts/UserContext";
import { useBackupContract } from "../../contexts/BackupContractContext";
import { useWallet } from "../../contexts/WalletContext";
import { GrRefresh } from "react-icons/gr";
import TransactionHash from "../common/TransactionHash/TransactionHash";
import ShareListRecover from "../common/shares/ShareListRecover";
import axios from "axios";
import { ShareInfo } from "@prifilabs/backup.js/lib/BackupClient";

type StepType = {
  setDisableNext: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  nextStep: () => void;
};

const RecoverStep1 = (props: StepType) => {
  const { backupContract, setBackupContract } = useBackupContract();
  const { keplr } = useWallet();
  const { userInfo } = useUser();
  const [value, setValue] = useState("");
  const [isRegisterSubmitting, setIsRegisterSubmitting] = useState(false);

  const [shareList, setShareList] = React.useState<ShareInfo[]>([]);

  async function loadShares() {
    if (keplr.backupClient && keplr.secretJS) {
      try {
        const shares = (await keplr.backupClient.getShareCount()).sort((a, b) =>
          Number(BigInt(b.timestamp) - BigInt(a.timestamp))
        );
        setShareList(shares);
      } catch (err) {
        console.error(err);
      }
    }
  }

  async function submitSelection(event: React.FormEvent) {
    event.preventDefault();
    setIsRegisterSubmitting(true);
    if (keplr.backupClient && keplr.secretJS) {
      try {
        const { tid, key, hash } = await keplr.backupClient.registerRecovery(
          userInfo.selected
        );
        if (process.env.REACT_APP_MOCK_MAILER && keplr.mailerClient) {
          const { code } = await keplr.mailerClient.getRecovery(tid);
          console.log("code", code);
        } else {
          await axios.post(
            process.env.REACT_APP_MAILER_URL + "/api/recovery",
            {
              tid,
            },
            { responseType: "text" }
          );
        }
        toast({
          title: "Step 2: Successfully Registered Recovery.",
          description: TransactionHash(hash),
          position: "bottom-right",
          status: "success",
          duration: null,
          isClosable: true,
        });
        setBackupContract({
          ...backupContract,
          key,
          hash,
          tid,
        });
        props.nextStep();
      } catch (err) {
        toast({
          title: "Step 2: Failed to Register Recovery.",
          description: TransactionHash(""),
          position: "bottom-right",
          status: "error",
          duration: null,
          isClosable: true,
        });
      }
    }
    setIsRegisterSubmitting(false);
  }

  const toast = useToast();

  return (
    <div>
      <Container>
        <Text p="10">
          <b>Step 2: Select Your Passphrase</b>
        </Text>
        <Text
          fontSize={useBreakpointValue(
            { base: "sm", md: "md" },
            { ssr: false }
          )}
          color="muted"
          alignItems={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          textAlign={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          paddingBottom={4}
        >
          Here are the passphrase shares that you own. You can recover any of
          these passphrases if and only if you have enough shares (meeting the
          threshold) and if it is associated to an email address that you own.
        </Text>
        <form onSubmit={submitSelection}>
          <FormControl mb="16">
            <Container display="flex" flexDirection="column">
              {!!shareList && shareList?.length > 0 && (
                <>
                  <FormLabel mb="20px" alignItems="left">
                    Select a passphrasse to recover:{" "}
                  </FormLabel>
                  <ShareListRecover
                    shareList={shareList}
                    value={value}
                    setValue={setValue}
                  />
                </>
              )}
              {shareList?.length === 0 && (
                <FormLabel alignSelf="center">You have no shares </FormLabel>
              )}
            </Container>
            <VStack mt="30px">
              <Button onClick={loadShares} rightIcon={<GrRefresh />}>
                {shareList ? "Refresh Shares" : "Load My Shares"}
              </Button>
              {shareList && (
                <Button
                  bg="orange.400"
                  color="white"
                  type="submit"
                  mt="10"
                  isLoading={isRegisterSubmitting}
                  isDisabled={!value}
                >
                  Confirm Selection
                </Button>
              )}
            </VStack>
          </FormControl>
        </form>
      </Container>
    </div>
  );
};

export default RecoverStep1;
