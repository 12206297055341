import { BrowserRouter, Route, Routes as RoutesHolder } from "react-router-dom";
import DashboardHome from "../pages/DashboardHome/DashboardHome";
import DashboardBackup from "../pages/DashboardBackup/DashboardBackup";
import DashboardRecover from "../pages/DashboardRecover/DashboardRecover";
import DashboardTransfer from "../pages/DashboardTransfer/DashboardTransfer";
import DashboardApprove from "../pages/DashboardApprove/DashboardApprove";
import PrivacyPolicy from "../pages/Business/PrivacyPolicy";
import TermsConditions from "../pages/Business/TermsConditions";

const PageRoutes = () => {
  return (
    <BrowserRouter>
      <RoutesHolder>
        <Route path="/" element={<DashboardHome />}></Route>
        <Route path="/backup" element={<DashboardBackup />}></Route>
        <Route path="/recovery" element={<DashboardRecover />}></Route>
        <Route path="/transfer" element={<DashboardTransfer />}></Route>
        <Route path="/approve" element={<DashboardApprove />}></Route>
        <Route path="/privacy" element={<PrivacyPolicy />}></Route>
        <Route path="/terms" element={<TermsConditions />}></Route>
      </RoutesHolder>
    </BrowserRouter>
  );
};

export default PageRoutes;
