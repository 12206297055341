import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
  HStack,
  Button,
  Container,
  Text,
} from "@chakra-ui/react";
import { useUser } from "../../contexts/UserContext";
import { useBackupContract } from "../../contexts/BackupContractContext";
import TransactionHash from "../common/TransactionHash/TransactionHash";
import { useWallet } from "../../contexts/WalletContext";

type StepType = {
  nextStep: () => void;
  setStep: (step: number) => void;
};

const ApproveStep2 = (props: StepType) => {
  const { userInfo, setUserInfo } = useUser();
  const { keplr } = useWallet();
  const { backupContract, setBackupContract } = useBackupContract();
  const [isFinalSubmitting, setisFinalSubmitting] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean | undefined>();
  const [failed, setFailed] = useState<boolean | undefined>();

  function startNewApproval() {
    setUserInfo({ ...userInfo, recipient: "" });
    setBackupContract({
      ...backupContract,
      tid: undefined,
      nonce: undefined,
      uuid: undefined,
      addr: undefined,
    });
    props.setStep(0);
  }

  const handleChangeWallet = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === backupContract.addr) {
      setValid(true);
    } else {
      setValid(false);
    }
    setUserInfo({ ...userInfo, recipient: event.target.value });
  };

  const toast = useToast();

  async function finalTransfer(event: React.FormEvent) {
    event.preventDefault();
    setisFinalSubmitting(true);
    if (keplr.backupClient && keplr.secretJS) {
      const { tid, nonce, addr } = backupContract;
      if (!nonce || !tid || !addr) {
        throw new Error("Fatal: Some prerequisite data is undefined");
      }
      try {
        const { hash } = await keplr.backupClient.approveTransfer(
          tid,
          nonce,
          addr
        );
        toast({
          title: "Step 3: Successfully Approved Transfer Request.",
          description: TransactionHash(hash),
          position: "bottom-right",
          status: "success",
          duration: null,
          isClosable: true,
        });
        // props.setDisableNext(false);
        setBackupContract({ ...backupContract, hash });
        props.nextStep();
      } catch (err) {
        toast({
          title: "Step 3: Failed to Approve Transfer.",
          description: TransactionHash(""),
          position: "bottom-right",
          status: "error",
          duration: null,
          isClosable: true,
        });
        setFailed(true);
      }
    }
    setisFinalSubmitting(false);
  }

  return (
    <div>
      <Container>
        <Text padding="10">
          <b>Step 3: Confirm Approval</b>
        </Text>
        <form onSubmit={finalTransfer}>
          <FormControl marginBottom="10">
            <FormLabel>Passphrase ID:</FormLabel>
            <Text marginBottom="4" fontSize="md" textAlign="left">
              <i>{backupContract.uuid ?? ""}</i>
            </Text>
            <FormLabel>Recipient Wallet Address:</FormLabel>
            <Text
              marginBottom="4"
              fontSize="md"
              textAlign="left"
              style={{
                msUserSelect: "none",
                MozUserSelect: "none",
                WebkitUserSelect: "none",
                WebkitTouchCallout: "none",
                KhtmlUserSelect: "none",
                userSelect: "none",
              }}
            >
              <i>{backupContract.addr ?? ""}</i>
            </Text>
            <FormLabel>
              Please confirm the new recipient address by re-entering it below:
            </FormLabel>
            <HStack>
              <Input
                marginBottom="4"
                required
                onChange={(e) => handleChangeWallet(e)}
              ></Input>
            </HStack>
            {userInfo.recipient &&
            backupContract.addr !== userInfo.recipient ? (
              <FormLabel marginBottom="4" color="orange.600">
                (!) WARNING: Current recipient address is different than initial
                address.
              </FormLabel>
            ) : null}
            <VStack>
              <Button
                bg="orange.400"
                color="white"
                type="submit"
                isDisabled={!valid}
                isLoading={isFinalSubmitting}
                marginTop="10"
                marginBottom="2"
              >
                Confirm Transfer
              </Button>
              {failed ? (
                <Button
                  onClick={() => startNewApproval()}
                  width="12em"
                  colorScheme="teal"
                >
                  Start a New Approval
                </Button>
              ) : null}
            </VStack>
          </FormControl>
        </form>
      </Container>
    </div>
  );
};

export default ApproveStep2;
