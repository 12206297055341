import React from "react";
import { Flex, Icon, Link, FlexProps } from "@chakra-ui/react";
import { IconType } from "react-icons";
import { trimPath } from "../../../utils/utils";

interface NavItemProps extends FlexProps {
  icon: IconType;
  name?: string;
  children: React.ReactNode;
  currentPath: string;
  handle: () => void;
}

export const NavItem = ({
  icon,
  name,
  children,
  handle,
  currentPath,
  ...rest
}: NavItemProps) => {
  return (
    <Link
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      onClick={() => handle()}
    >
      {trimPath(currentPath) === name ? (
        <Flex
          align="center"
          p="2"
          fontSize={"md"}
          bg={"teal.400"}
          color={"white"}
          mx="2"
          borderRadius="xl"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "teal.400",
            color: "white",
            transition: "0.1s",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="3"
              fontSize="16"
              _groupHover={{
                color: "white",
                transition: "0.1s",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      ) : (
        <Flex
          align="center"
          p="2"
          fontSize={"md"}
          mx="2"
          borderRadius="xl"
          role="group"
          cursor="pointer"
          _hover={{
            bg: "teal.400",
            color: "white",
            transition: "0.1s",
          }}
          {...rest}
        >
          {icon && (
            <Icon
              mr="3"
              fontSize="16"
              _groupHover={{
                color: "white",
                transition: "0.1s",
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      )}
    </Link>
  );
};

export default NavItem;