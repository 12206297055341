import React, { useCallback, useEffect, useRef, useState } from "react";
import { Keplr } from "@keplr-wallet/types";
import { Window as KeplrWindow } from "@keplr-wallet/types";
import { SecretNetworkClient, Wallet } from "secretjs";
import { AminoSigner } from "secretjs/dist/wallet_amino";
import { BackupClient, MailerClient } from "@prifilabs/backup.js";

const BACKUP_CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS!;
const BACKUP_CONTRACT_HASH = process.env.REACT_APP_CONTRACT_HASH!;
const CHAIN_ID = process.env.REACT_APP_SECRET_CHAIN!;
const SECRET_URL = process.env.REACT_APP_SECRET_ENDPOINT!;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Window extends KeplrWindow {}
}

type KeplrObjectType = {
  offlineSigner: AminoSigner | undefined;
  secretJS: SecretNetworkClient | undefined;
  backupClient: BackupClient | undefined;
  mailerClient: MailerClient | undefined;
};

const keplrInitialState = {
  offlineSigner: undefined,
  secretJS: undefined,
  backupClient: undefined,
  mailerClient: undefined,
};

type WalletType = {
  keplr: KeplrObjectType;
  setKeplr: React.Dispatch<React.SetStateAction<KeplrObjectType>>;
  keplrSetup: () => Promise<void>;
};

type ProviderProps = {
  children?: React.ReactNode;
};

export const getKeplr = async (): Promise<Keplr | undefined> => {
  if (window.keplr) {
    return window.keplr;
  }

  if (document.readyState === "complete") {
    return window.keplr;
  }

  return new Promise((resolve) => {
    const documentStateChange = (event: Event) => {
      if (
        event.target &&
        (event.target as Document).readyState === "complete"
      ) {
        resolve(window.keplr);
        document.removeEventListener("readystatechange", documentStateChange);
      }
    };
    document.addEventListener("readystatechange", documentStateChange);
  });
};

const Context = React.createContext<WalletType>({} as WalletType);

const WalletProvider = (props: ProviderProps) => {
  const [keplr, setKeplr] = useState<KeplrObjectType>({ ...keplrInitialState });
  const isEvent = useRef(false);

  const keplrSetup = useCallback(async () => {
    const windowKeplr = await getKeplr();
    if (!windowKeplr) {
      return;
    }
    await windowKeplr.experimentalSuggestChain({
      chainId: "pulsar-2",
      chainName: "Secret Pulsar",
      rpc: "https://rpc.pulsar.scrttestnet.com",
      rest: "https://api.pulsar.scrttestnet.com",
      bip44: {
        coinType: 529,
      },
      coinType: 529,
      stakeCurrency: {
        coinDenom: "SCRT",
        coinMinimalDenom: "uscrt",
        coinDecimals: 6,
      },
      bech32Config: {
        bech32PrefixAccAddr: "secret",
        bech32PrefixAccPub: "secretpub",
        bech32PrefixValAddr: "secretvaloper",
        bech32PrefixValPub: "secretvaloperpub",
        bech32PrefixConsAddr: "secretvalcons",
        bech32PrefixConsPub: "secretvalconspub",
      },
      currencies: [
        {
          coinDenom: "SCRT",
          coinMinimalDenom: "uscrt",
          coinDecimals: 6,
        },
      ],
      feeCurrencies: [
        {
          coinDenom: "SCRT",
          coinMinimalDenom: "uscrt",
          coinDecimals: 6,
          gasPriceStep: {
            low: 0.1,
            average: 0.25,
            high: 0.4,
          },
        },
      ],
      features: ["secretwasm", "ibc-transfer", "ibc-go"],
      beta: true,
    });
    await windowKeplr.enable(CHAIN_ID);
    await keplrSetupHelper(windowKeplr);

    if (!isEvent.current) {
      window.addEventListener("keplr_keystorechange", async () => {
        console.log(
          "Key store in Keplr is changed. You may need to refetch the account info."
        );
        await keplrSetupHelper(windowKeplr);
      });
      isEvent.current = true;
    }
  }, []);

  useEffect(
    function () {
      const session = sessionStorage.getItem("session");
      if (session === "true") {
        keplrSetup().catch((err) => {
          sessionStorage.setItem("session", "false");
        });
      }
    },
    [keplrSetup]
  );

  const keplrSetupHelper = async (windowKeplr: Keplr) => {
    const keplrOfflineSigner = windowKeplr.getOfflineSignerOnlyAmino(CHAIN_ID);
    const accounts = await keplrOfflineSigner.getAccounts();
    const secretJS = new SecretNetworkClient({
      url: SECRET_URL,
      chainId: CHAIN_ID,
      wallet: keplrOfflineSigner,
      walletAddress: accounts[0].address,
      encryptionUtils: windowKeplr.getEnigmaUtils(CHAIN_ID),
    });
    sessionStorage.setItem("session", "true");

    let mailerClient;
    if (process.env.REACT_APP_MOCK_MAILER) {
      const mailerWallet = new Wallet(
        process.env.REACT_APP_MOCK_MAILER_MNEMONIC
      );
      const mailerSecretJs = new SecretNetworkClient({
        url: SECRET_URL,
        chainId: CHAIN_ID,
        wallet: mailerWallet,
        walletAddress: mailerWallet.address,
        encryptionUtils: windowKeplr.getEnigmaUtils(CHAIN_ID),
      });
      mailerClient = new MailerClient(
        BACKUP_CONTRACT_ADDRESS,
        BACKUP_CONTRACT_HASH,
        mailerWallet,
        mailerSecretJs,
        CHAIN_ID
      );
    }

    const backupClient = new BackupClient(
      secretJS,
      keplrOfflineSigner,
      BACKUP_CONTRACT_ADDRESS,
      BACKUP_CONTRACT_HASH,
      CHAIN_ID,
      window.crypto,
      true
    );

    setKeplr({
      offlineSigner: keplrOfflineSigner,
      secretJS,
      backupClient,
      mailerClient,
    });
  };

  return (
    <Context.Provider value={{ keplr, setKeplr, keplrSetup }}>
      {props.children}
    </Context.Provider>
  );
};

export default WalletProvider;

export const useWallet = () => React.useContext(Context);
