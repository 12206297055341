import React from "react";
import { Link, Text } from "@chakra-ui/layout";
import { trimHash } from "../../../utils/utils";

export const TransactionHash = (hash: string) => {
  return (
    <div>
      {hash.length !== 0 ? (
        <Text>
          View Tx Here:{" "}
          <Link
            href={
              process.env.REACT_APP_SECRET_CHAIN === "pulsar-2"
                ? "https://secretnodes.com/pulsar/transactions/" + hash
                : "https://atomscan.com/secret-network/transactions/" + hash
            }
            isExternal
            color="teal.100"
          >
            {trimHash(hash)}
          </Link>
        </Text>
      ) : (
        <Text>Please try again.</Text>
      )}
    </div>
  );
};

export default TransactionHash;
