import React from "react";
import { FormLabel, Tooltip, Text } from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";

type LabelTooltipProps = {
  label: string;
  text: string;
  children?: React.ReactNode;
};

type ToolTipPropsQuestion = {
  text: string;
};

export const LabelTooltip = (props: LabelTooltipProps) => {
  return (
    <FormLabel mt="5" display="flex" alignItems="center" gap="10px">
      <Text>{props.label}</Text>
      <ToolTipQuestion text={props.text} />
      {props.children}
    </FormLabel>
  );
};

export const ToolTipQuestion = (props: ToolTipPropsQuestion) => {
  return (
    <Tooltip
      label={props.text}
      bg="teal.500"
      rounded="2xl"
      color="white"
      placement="right"
    >
      <QuestionIcon color="teal.400" boxSize={"5"} />
    </Tooltip>
  );
};
