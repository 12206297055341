import {
  Container,
  Text,
  Box,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";

import ConnectWalletModal from "../common/ConnectWallet/ConnectWalletModal";
import { useWalletModal } from "../../contexts/WalletModalContext";

type StepType = {
  nextStep: () => void;
};

const ApproveStep0 = (props: StepType) => {
  const { navModalHelper } = useWalletModal();

  function next() {
    return navModalHelper(() => props.nextStep());
  }

  return (
    <div>
      <Container>
        <ConnectWalletModal />
        <Box
          fontSize={useBreakpointValue(
            { base: "sm", md: "md" },
            { ssr: false }
          )}
          color="muted"
          alignItems={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          textAlign={useBreakpointValue(
            {
              base: "center",
              md: "left",
            },
            { ssr: false }
          )}
          padding={useBreakpointValue({ base: "0", md: "12" }, { ssr: false })}
        >
          <Text marginTop={2} fontSize="md">
            <b>Approve:</b>
          </Text>
          <Text marginTop={2} textAlign="left">
            When someone request to transfer a share for one of your passphrase,
            you need to approve it. To finalize the transfer, use the
            verification code that was sent to the original email used to backup
            that passphrase.
          </Text>
        </Box>
        <Button
          bg="orange.400"
          color="white"
          onClick={next}
          marginTop={4}
          size={useBreakpointValue({ base: "sm", md: "md" }, { ssr: false })}
        >
          Let's start
        </Button>
      </Container>
    </div>
  );
};

export default ApproveStep0;
